import $ from 'jquery'
import {
  registerURL,
  loginURL,
  codeURL
} from '@/utils/PAGE-CONFIG'

export const toTop = () => {
  $("#pageContent").animate({
    scrollTop: 0
  }, 800);
}

export const linkToRegister = () => {
  window.open(registerURL, '_self')
}
export const linkToLogin = () => {
  window.open(loginURL, '_self')
}
export const linkToCode = () => {
  window.open(codeURL, '_blank')
}