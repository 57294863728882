<template>
    <div class="applicationConstruction-container">
        <span class="applicationConstruction-title">应用搭建服务</span>
        <div class="applicationConstruction-describe">
            <span>为了满足客户“</span>
            <span style="color: #23c5d0;">多、快、好、省</span>
            <span>”的轻量化需求，我们可提供轻应用搭建服务，根据用户需求，提供</span>
            <span style="color: #23c5d0;">三维建模、UI设计、交互设计</span>
            <span>在内的前期设计服务，以及</span>
            <span style="color: #23c5d0;">物联网数据对接、数据业务流转、数据分析、数字孪生</span>
            <span>等应用搭建服务，48小时内快速搭建业务应用场景，比如</span>
            <span style="color: #23c5d0;">安全监测、物联网、大屏展示</span>
            <span>等。</span>
        </div>
        <div class="applicationConstruction-pc">
            <div class="applicationConstruction-pc-menu">
                <div class="applicationConstruction-pc-menu-item" :class="item.id === value? 'selcet-item' : ''" v-for="item in menu" :key="item.id" @click="onApplicationConstructionClick(item.id)">
                    <img class="applicationConstruction-menu-img" alt="example" :src="item.imgUrl" />
                    <span class="applicationConstruction-menu-name">{{ item.name }}</span>
                </div>
            </div>
            <div class="applicationConstruction-pc-right">
                <div class="applicationConstruction-pc-main">
                    <img alt="example" v-for="item in mainImg" :key="item.id" :src="item.imgUrl" />
                </div>
                <div class="applicationConstruction-pc-button">
                    <a-button class="button-more" type="primary" @click="openContactModal">了解更多　></a-button>
                </div>
            </div>
        </div>
        <div class="applicationConstruction-phone">
            <div class="applicationConstruction-phone-menu" ref="container">
                <div class="applicationConstruction-phone-menu-item" :class="item.id === value? 'selcet-item' : ''" v-for="item in menu" :key="item.id" @click="onApplicationConstructionClick(item.id)">
                    <img class="applicationConstruction-menu-img" alt="example" :src="item.imgUrl" />
                    <span class="applicationConstruction-menu-name">{{ item.name }}</span>
                </div>
            </div>
            <div class="applicationConstruction-phone-main">
                <img alt="example" v-for="item in mainImg" :key="item.id" :src="item.imgUrl" />
            </div>
            <div class="applicationConstruction-phone-button">
                <a-button class="button-more" type="primary" @click="openContactModal">了解更多　></a-button>
            </div>
        </div>
        <contact-buy :visible="contactModalVisible" title="了解更多" @close="closeContactModal"></contact-buy>
    </div>
  </template>
    
  <script setup>
    import { ref, onMounted } from 'vue';
    import contactBuy from '../price/contactBuy.vue'
    let contactModalVisible = ref(false)
    const value = ref('3d')
    const menu = ref([
        {
            id:'3d',
            name: '3D建模',
            imgUrl: require('@image/applicationConstruction/applicationConstruction-3d.png'),
        },{
            id:'ui',
            name: ' UI设计',
            imgUrl:require('@image/applicationConstruction/applicationConstruction-ui.png'),
        },{
            id:'jhsj',
            name: '交互设计',
            imgUrl:require('@image/applicationConstruction/applicationConstruction-jhsj.png'),
        },{
            id:'wlwsjdj',
            name: '物联网数据对接',
            imgUrl:require('@image/applicationConstruction/applicationConstruction-wlwsjdj.png'),
        },{
            id:'sjywlz',
            name: '数据业务流转',
            imgUrl:require('@image/applicationConstruction/applicationConstruction-sjywlz.png'),
        },{
            id:'sjfx',
            name: '数据分析',
            imgUrl:require('@image/applicationConstruction/applicationConstruction-sjfx.png'),
        },{
            id:'sjls',
            name: '数字孪生',
            imgUrl:require('@image/applicationConstruction/applicationConstruction-szls.png'),
        },
    ])
    const mainImg = ref([
        {
            id:'3d',
            imgUrl: require('@image/applicationConstruction/applicationConstruction-main-3d.png'),
        }
    ])
    const onApplicationConstructionClick = (id) => {
        value.value = id
    }
    const openContactModal = () => {
        contactModalVisible.value = true
    }
    const closeContactModal = () => {
        contactModalVisible.value = false
    }
    const container = ref(null)
    onMounted(() => {
        let isDown = false, // 是否按下鼠标
            startX, // 鼠标起始位置
            scrollLeft; // 滚动条位置

        container.value.addEventListener('mousedown', (e) => {
          isDown = true;
          startX = e.pageX - container.value.offsetLeft; 
          scrollLeft = container.value.scrollLeft;
        });

        container.value.addEventListener('mouseup', () => {
          isDown = false;
        });

        container.value.addEventListener('mousemove', (e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - container.value.offsetLeft;
          const walk = (x - startX) * 2;
          container.value.scrollLeft = scrollLeft - walk;
        });
    })
  </script>
    
  <style scoped>
    .applicationConstruction-container {
        text-align: center;
    }
    .applicationConstruction-title {
        font-family: "SourceHanSansCN-Bold";
        font-size: 4.8rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
        color: #333333;
    }
    .applicationConstruction-describe{
        color: #333333;
    }
    .applicationConstruction-pc{
        max-width: 1200px;
        margin: auto;
        display: flex;
        align-items: center;
    }
    .applicationConstruction-pc-menu{
        width: 220px;
    }
    .applicationConstruction-pc-menu-item{
        display: flex;
        height: 61px;
        margin-bottom: 20px;
        cursor:pointer;
    }
    .selcet-item{
        background-image: url(~@image/applicationConstruction/applicationConstruction-select.png);
        background-size: 100% 100%;
    }
    .selcet-item .applicationConstruction-menu-name{
        color: #23c5d0;
    }
    .applicationConstruction-menu-img{
        width: 70px;
        height: 77px;
    }
    .applicationConstruction-menu-name{
        color: #1E1E1E;
        line-height: 61px;
        margin-left: -10px;
    }
    .applicationConstruction-pc-right{
        width: calc(100% - 300px);
        text-align: left;
        margin: 0px 80px;
    }
    .applicationConstruction-pc-main{
        padding: 35px 60px 50px 50px;
        background-image: url(~@image/applicationConstruction/applicationConstruction-main-bgc.png);
        background-size: 100% 100%;
    }
    .applicationConstruction-pc-main img{
        width: 100%;
    }
    .button-more{
        width: 170px;
        height: 50px;
        background: #23C5D0;
        border: none;
        margin-left: 25px;
    }
    .button-more.ant-btn-primary:hover, .button-more.ant-btn-primary:focus{
        background: #23C5D0;
    }


    .applicationConstruction-phone{
        width: 100%;
    }
    .applicationConstruction-phone-menu{
        display: flex;
        text-align: center;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
    }
    .applicationConstruction-phone-menu-item{
        padding: 0px 10px 0px 0px;
        height: 81px;
        cursor:pointer;
        display: flex;
        align-items: center;
    }
    .applicationConstruction-phone-menu-item img{
        margin-top: 10px;
    }
    .applicationConstruction-phone-main{
        width: 100%;
        padding: 30px 50px 40px 40px;
        background-image: url(~@image/applicationConstruction/applicationConstruction-main-bgc.png);
        background-size: 100% 100%;
    }
    .applicationConstruction-phone-main img{
        width: 100%;
    }
    @media screen and (min-width: 800px) and (max-width: 1000px) {
        .applicationConstruction-pc-main{
            padding: 20px 35px 30px 30px;
        }
    }
  @media screen and (min-width: 800px) {
    .applicationConstruction-container {
        padding: 100px 0px;
    }
    .applicationConstruction-phone{
        display: none;
    }
    .applicationConstruction-describe{
        font-size: 16px;
        width: 800px;
        margin: 40px auto;
    }
  }
  
  @media screen and (max-width: 800px) {
    .applicationConstruction-title{
      font-size: 8rem;
    }
    .applicationConstruction-container {
        padding-top: 40px;
        padding-bottom: 20px;
    }
    .applicationConstruction-pc{
        display: none;
    }
    .applicationConstruction-describe{
        font-size: 14px;
        margin: 20px 0px;
        padding: 0px 10rem;
    }
  }
  </style>
  