<template>
    <div>
      <Banner></Banner>
      <partner></partner>
      <!-- <div class="home-backimg" :style="{background: `url(${require('@image/body_bg2.png')})`, 'background-size': 'cover'}"> -->
        <Features></Features>
        <Scene></Scene>
        <Honor></Honor>
      <!-- </div> -->
      <FooterBanner></FooterBanner>
    </div>
</template>
  
  <script>
    import {
      defineComponent,
    } from 'vue'
    import Banner from './banner.vue'
    import Features from './features.vue'
    import Scene from './scene.vue'
    import Honor from './honor.vue'
    import partner from './partner.vue'
    import FooterBanner from './footerBanner.vue'
    export default defineComponent({
      name: 'App',
      components: {
        Banner,
        Features,
        Scene,
        Honor,
        partner,
        FooterBanner
      },
      setup() {
        return {
        }
      }
    })
  
  </script>
  <style scoped>
    .home-backimg{
      /* background-image: url("../../assets/image/body_bg.png"); */
    }
  </style>
  
