export const NAV_CONFIG = [{
    name: '首页',
    id: 'home',
    path: '/home',
    select: false
  },
  {
    name: '产品',
    id: 'product',
    select: false,
    children: [{
        id: '1',
        name: '零代码产品',
        children: [{
          id: '1.1',
          name: 'MAPaaS'
        }]
      },
      {
        id: '2',
        name: '行业应用产品',
        children: [{
            id: '2.1',
            name: '矿山综合管控'
          },
          {
            id: '2.2',
            name: '矿山地质保障'
          },
          {
            id: '2.3',
            name: '洗煤厂智能化监控'
          },
          {
            id: '2.4',
            name: '尾矿库在线监测'
          },
          {
            id: '2.5',
            name: '微震监测'
          },
          {
            id: '2.6',
            name: '三维地质建模'
          },
          {
            id: '2.7',
            name: '智慧工地'
          },
          {
            id: '2.8',
            name: '轨道交通风险管控'
          },
          {
            id: '2.9',
            name: '智慧海洋环境'
          },
          {
            id: '2.10',
            name: '智慧社区'
          },
          {
            id: '2.11',
            name: '其他'
          }
        ]
      },
      {
        id: '3',
        name: '算法',
        children: [{
            id: '3.1',
            name: '矿山巷道建模算法'
          },
          {
            id: '3.2',
            name: '三维地质建模算法'
          }
        ]
      },
      {
        id: '4',
        name: '增值服务',
        children: [{
            id: '4.1',
            name: '平台运维'
          },
          {
            id: '4.2',
            name: '产品培训'
          }
        ]
      }
    ]
  },
  {
    name: '示例',
    id: 'example',
    path: '/example',
    select: false
  },
  {
    name: '定价',
    id: 'price',
    path: '/price',
    select: false
  },
  {
    name: '资源',
    id: 'resource',
    path: '/resource',
    link: 'https://www.yuque.com/ctkj/cgscloud?# 《cgscloud》',
    select: false,
    // children: [
    //   {
    //     id: 1,
    //     name: '视频中心',
    //   },
    //   {
    //     id: 2,
    //     name: '帮助中心',
    //   },
    // ]
  },
  {
    name: '服务',
    id: 'serve',
    path: '/serve',
    select: false
  },
  {
    name: '关于畅图',
    id: 'about',
    link: '/',
    select: false
  }
]

export const registerURL = 'https://app.mapaas.com/register'

export const loginURL = 'https://app.mapaas.com'

export const codeURL = 'https://gitee.com/cgscloud/cgscloud'

export const PRICE_DETAIL_CONFIG = [{
    key: '0',
    header: {
      title: '数据采集'
    },
    level: [{
        header: {
          key: "0-1",
          title: 'DAS-物联网数据采集（交互操作形式）',
        },
        detail: [{
          title: '应用数量',
          valueList: [{
            value: '1',
            iconable: ''
          }, {
            value: '5',
            iconable: ''
          }, {
            value: '不限',
            iconable: ''
          }]
        }, {
          title: 'TCP',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: 'Http',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: 'Modbus',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }]
      },
      {
        header: {
          key: "0-2",
          title: 'IoT-物联网数据采集（工作流形式）',
          valueList: []
        },
        detail: [{
          title: '应用数量',
          valueList: [{
            value: '1',
            iconable: ''
          }, {
            value: '不限',
            iconable: ''
          }, {
            value: '不限',
            iconable: ''
          }]
        }, {
          title: '采集工作流',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }]
      }
    ]
  },
  {
    key: '1',
    header: {
      title: '数据存储与管理'
    },
    level: [{
        header: {
          key: '1-0',
          title: 'Base-对业务数据与空间数据的表单化管理与分析处理',
        },
        detail: [{
          title: '应用数量',
          valueList: [{
            value: '1',
            iconable: ''
          }, {
            value: '20',
            iconable: ''
          }, {
            value: '不限',
            iconable: ''
          }]
        }, {
          title: 'DAS工作表',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '主题管理',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '基本字段',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '高级字段',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '图表组件',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '高级组件',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '表单工作流',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '打印模板',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '移动应用',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '数据集成',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }]
      },
      {
        header: {
          key: '1-1',
          title: 'File-对文档文件等的网盘存储',
        },
        detail: [{
          title: '应用数量',
          valueList: [{
            value: '1',
            iconable: ''
          }, {
            value: '5',
            iconable: ''
          }, {
            value: '不限',
            iconable: ''
          }]
        }, {
          title: '目录管理',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '文件管理',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '快捷访问',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }, {
          title: '回收站',
          valueList: [{
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }, {
            value: 2,
            iconable: true
          }]
        }]
      }
    ]
  },
  {
    key: '2',
    header: {
      title: '地学建模与分析',
    },
    level: [{
      header: {
        key: '2-1',
        title: 'Workflow-丰富的数据融合、数据转换、数据后处理、地学建模、智能分析、预测预警等算法',
      },
      detail: [{
        title: '应用数量',
        valueList: [{
          value: '1',
          iconable: ''
        }, {
          value: '5',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }, {
        title: '数值分析',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '巷道建模',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '钻孔建模',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '属性建模',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '数据处理',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '模型分析',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }]
    }]
  },
  {
    key: '3',
    header: {
      title: '数据服务',
    },
    level: [{
      header: {
        key: '3-1',
        title: 'DataServer-二三维空间数据预处理、在线发布管理'
      },
      detail: [{
        title: '应用数量',
        valueList: [{
          value: '1',
          iconable: ''
        }, {
          value: '20',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }, {
        title: '地图服务',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '瓦片服务',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '模型服务',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '倾斜摄影服务',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }]
    }, {
      header: {
        key: '3-2',
        title: 'GeoModel-地学模型处理和发布管理',
      },
      detail: [{
        title: '应用数量',
        valueList: [{
          value: '1',
          iconable: ''
        }, {
          value: '5',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }, {
        title: '模型可视化',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '外部模型支持',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '表单模型支持',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '模型分析',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '模型发布',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }]
    }]
  }, {
    key: '4',
    header: {
      title: '可视化'
    },
    level: [{
      header: {
        key: '4-1',
        title: '2D-二维地图'
      },
      detail: [{
        title: '应用数量',
        valueList: [{
          value: '1',
          iconable: ''
        }, {
          value: '5',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }, {
        title: '基础组件',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '高级组件',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }]
    }, {
      header: {
        key: '4-2',
        title: '3D-三维局部场景'
      },
      detail: [{
        title: '应用数量',
        valueList: [{
          value: '1',
          iconable: ''
        }, {
          value: '5',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }, {
        title: '基础组件',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '高级组件',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }]
    }, {
      header: {
        key: '4-3',
        title: 'Earth-三维大场景'
      },
      detail: [{
        title: '应用数量',
        valueList: [{
          value: '1',
          iconable: ''
        }, {
          value: '5',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }, {
        title: '基础组件',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '高级组件',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }]
    }, {
      header: {
        key: '4-4',
        title: 'Map-矿山一张图'
      },
      detail: [{
        title: '应用数量',
        valueList: [{
          value: '1',
          iconable: ''
        }, {
          value: '5',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }, {
        title: '基础组件',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '高级组件',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }]
    }, {
      header: {
        key: '4-5',
        title: 'CAD-采矿设计'
      },
      detail: [{
        title: '应用数量',
        valueList: [{
          value: '1',
          iconable: ''
        }, {
          value: '5',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }, {
        title: '基础组件',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '高级组件',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }]
    }, {
      header: {
        key: '4-6',
        title: 'Scada-设备和流程的可视化组态监视'
      },
      detail: [{
        title: '应用数量',
        valueList: [{
          value: '1',
          iconable: ''
        }, {
          value: '10',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }, {
        title: '组态画面',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '数据接入',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }]
    }]
  }, {
    key: '5',
    header: {
      title: '系统集成'
    },
    level: [{
      header: {
        key: '5-1',
        title: 'Page-系统集成'
      },
      detail: [{
        title: '应用数量',
        valueList: [{
          value: '1',
          iconable: ''
        }, {
          value: '5',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }, {
        title: '静态页面',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '系统集成',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }]
    }]
  }, {
    key: '6',
    header: {
      title: '其他'
    },
    level: [{
      header: {
        key: '6-1',
        title: '系统管理'
      },
      detail: [{
        title: '用户数量',
        valueList: [{
          value: '1人',
          iconable: ''
        }, {
          value: '100人',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }, {
        title: '版本标识',
        valueList: [{
          value: '不支持修改',
          iconable: ''
        }, {
          value: '支持修改',
          iconable: ''
        }, {
          value: '支持修改',
          iconable: ''
        }]
      }, {
        title: '系统管理员',
        valueList: [{
          value: '1人',
          iconable: ''
        }, {
          value: '1人',
          iconable: ''
        }, {
          value: '不限',
          iconable: ''
        }]
      }]
    }, {
      header: {
        key: '6-2',
        title: '服务支持'
      },
      detail: [{
        title: '咨询答疑',
        valueList: [{
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }, {
        title: '技术培训',
        valueList: [{
          value: 0,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }, {
          value: 2,
          iconable: true
        }]
      }]
    }]
  }
]

