<template>
  <div style="min-height: 479px;text-align:center;margin-top:140px">
    <span class="home-honor-title">荣誉奖项</span>
    <div class="home-honor-show">
      <div class="home-honor-item" v-for="item in honorList" :key="item.id">
        <div class="home-honor-img">
          <img :src="item.imgUrl">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  onMounted
} from 'vue'
export default defineComponent({
  setup() {
    let honorList = reactive([
    {id: '18',imgUrl: require('@image/honor/image24.png'),text: '北京市专精特新中小企业'},
    {id: '6',imgUrl: require('@image/honor/image6.png'),text: '国家高新技术企业认证'},
    {id: '14',imgUrl: require('@image/honor/image14.png'),text: '科技型中小企业'},
    {id: '18',imgUrl: require('@image/honor/image18.png'),text: '北京市“创新型”中小企业'},
      {id: '16',imgUrl: require('@image/honor/image22.png'),text: '北京市双软认证企业'},
      {id: '17',imgUrl: require('@image/honor/image23.png'),text: '北京信创'},
      {id: '7',imgUrl: require('@image/honor/image7.png'),text: '中关村高新技术企业'},
      {id: '17',imgUrl: require('@image/honor/image17.png'),text: '软件能力成熟度三级认证'},
      {id: '18',imgUrl: require('@image/honor/image25.png'),text: 'ITSS信息技术三级'},
      {id: '4',imgUrl: require('@image/honor/image4.png'),text: '华为生态合作伙伴'},
      {id: '16',imgUrl: require('@image/honor/image19.png'),text: '华为技术认证'},
      {id: '17',imgUrl: require('@image/honor/image20.png'),text: '鲲鹏技术认证'},
      {id: '3',imgUrl: require('@image/honor/image3.png'),text: '飞腾兼容认证'},
      {id: '5',imgUrl: require('@image/honor/image5.png'),text: '壹计划企业'},
      {id: '8',imgUrl: require('@image/honor/image8.png'),text: 'ISO9001质量管理体系认证'},
      {id: '18',imgUrl: require('@image/honor/image21.png'),text: '北京软件和信息服务业协会理事会员单位'},
      {id: '15',imgUrl: require('@image/honor/image15.png'),text: '中国移动合作伙伴'},
      // {id: '9',imgUrl: require('@image/honor/image9.png')},
      {id: '13',imgUrl: require('@image/honor/image13.png'),text: '中国城科会地下空间专委会委员'},
      // {id: '11',imgUrl: require('@image/honor/image11.png')},
      // {id: '12',imgUrl: require('@image/honor/image12.png')}
      {id: '16',imgUrl: require('@image/honor/image16.png'),text: '中关村数字建筑绿色发展联盟理事会会员单位'},
      {id: '10',imgUrl: require('@image/honor/image10.png'),text: '矿山应急与安全工作委员会会员企业'},

      
    ])

    return {
      honorList
    }
  }

})
</script>

<style lang="less" scoped>
.home-honor-title{
  font-family: "SourceHanSansCN-Bold";
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  color: #333333;
}
.home-honor-show{
  max-width: 1320px;
  margin: auto;
  margin-top: 7.5rem;
  display: flex;
  flex-wrap: wrap;

  .home-honor-item{
    width: 200px;
    height: 148px;
    margin: 10px;
    margin-bottom: 3rem;
    text-align: center;
    padding: 0 10px;

    .home-honor-img{
      width: 200px;
      height: 148px;

      // img{
      //   transform:scale(0.8);
      // }
    }

  }
}
@media screen and (max-width: 800px){
  .home-honor-title{
    font-size: 8rem;
  }
  .home-honor-show{
    display: flex;
    justify-content: center;
    .home-honor-item{
      width: 170px;
      height: 125.8px;
      margin: 0;
      margin: 1rem;
      text-align: center;
      padding: 0;

      .home-honor-img{
        width: 170px;
        height: 125.8px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
