<template>
    <div class="maskDiv-modal">
      <div class="maskDiv-modal-back"><left-outlined @click="goBack"/></div>
      <div class="maskDiv-modal-header">
        <div class="maskDiv-modal-header-text">
          畅图云商务咨询表单
        </div>
      </div>
      <div class="maskDiv-modal-main">
        <a-form ref="formRef" :model="formState" layout="vertical" name="form_in_modal" @finish="formSubmit">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-item
                name="consultation"
                label="咨询类型："
                :rules="[{ required: true, message: '请选择咨询类型!' }]"
              >
              <a-radio-group v-model:value="formState.consultation">
                <a-radio value="新购">新购</a-radio>
                <a-radio value="续费">续费</a-radio>
                <a-radio value="升级">升级</a-radio>
                <a-radio value="实施">实施</a-radio>
              </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-item
                name="name"
                label="您的称呼："
                :rules="[{ required: true, message: '请填写您的称呼!' }]"
              >
                <a-input v-model:value="formState.name" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="userID"
                label="用户注册id："
              >
                <a-input v-model:value="formState.userID" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-item
                name="companyName"
                label="公司名称："
                :rules="[{ required: true, message: '请填写公司名称!' }]"
              >
                <a-input v-model:value="formState.companyName" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="contact"
                label="您的联系方式："
                :rules="[{ required: true, message: '请填写您的联系方式!' }]"
              >
                <a-input v-model:value="formState.contact" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item
            name="demand"
            label="简单说明您的需求："
            :rules="[{ required: true, message: '请填写您的需求!' }]"
          >
            <a-textarea v-model:value="formState.demand" :rows="4" />
          </a-form-item>
          <a-form-item d class="collection-create-form_last-form-item">
            <a-button html-type="submit" type="primary" style="background:#00b1c1;width:120px;height:35px;">提&emsp;交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="maskDiv-modal-mobile">
      <div class="maskDiv-modal-back"><left-outlined @click="goBack"/></div>
      <div class="maskDiv-modal-header">
        <div class="maskDiv-modal-header-text">
          畅图云商务咨询表单
        </div>
      </div>
      <div class="maskDiv-modal-main">
        <a-form ref="formRef" :model="formState" layout="vertical" name="form_in_modal" @finish="formSubmit">

              <a-form-item
                name="consultation"
                label="咨询类型："
                :rules="[{ required: true, message: '请选择咨询类型!' }]"
              >
              <a-radio-group v-model:value="formState.consultation">
                <a-radio value="新购">新购</a-radio>
                <a-radio value="续费">续费</a-radio>
                <a-radio value="升级">升级</a-radio>
                <a-radio value="实施">实施</a-radio>
              </a-radio-group>
              </a-form-item>

              <a-form-item
                name="name"
                label="您的称呼："
                :rules="[{ required: true, message: '请填写您的称呼!' }]"
              >
                <a-input v-model:value="formState.name" />
              </a-form-item>

              <a-form-item
                name="userID"
                label="用户注册id："
              >
                <a-input v-model:value="formState.userID" />
              </a-form-item>

              <a-form-item
                name="companyName"
                label="公司名称："
                :rules="[{ required: true, message: '请填写公司名称!' }]"
              >
                <a-input v-model:value="formState.companyName" />
              </a-form-item>

              <a-form-item
                name="contact"
                label="您的联系方式："
                :rules="[{ required: true, message: '请填写您的联系方式!' }]"
              >
                <a-input v-model:value="formState.contact" />
              </a-form-item>

          <a-form-item
            name="demand"
            label="简单说明您的需求："
            :rules="[{ required: true, message: '请填写您的需求!' }]"
          >
            <a-textarea v-model:value="formState.demand" :rows="4" />
          </a-form-item>
          <a-form-item d class="collection-create-form_last-form-item">
            <a-button html-type="submit" type="primary" style="background:#00b1c1;width:120px;height:35px;">提&emsp;交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
</template>

<script setup>
import { ref , defineProps , watch , defineEmits , h} from 'vue'
import { useRouter } from 'vue-router';
import { LeftOutlined , SmileOutlined , FrownTwoTone } from '@ant-design/icons-vue';
import { addConsultation } from '@/api/example.js'
import { notification } from 'ant-design-vue';

const flag = defineProps(['modalFlag'])
const emit = defineEmits()
const modalFlag = ref()

const formRef = ref()
const router = useRouter()
const formState = ref({
  consultation:'',
  name:'',
  userID:'',
  companyName:'',
  contact:'',
  demand:''
})

watch(() => flag.modalFlag, (newValue) => {
  modalFlag.value = newValue
})

const goBack = () => {
  router.back()
}

const formSubmit = async (val)=>{
  if(val){
    let obj = {
      "radio104803" : val.consultation,
      "input44869" : val.name,
      "input26129" : val.userID,
      "input62838" : val.companyName,
      "number67674" : val.contact,
      "textarea84662" : val.demand
    }
    const result = await addConsultation(obj)
    if(result.code == 200){
      router.back()
      notification.open({
        message: '提交成功',
        description:'我们的工作人员将会在3个工作日内与您取得联系，请耐心等待',
        icon: () => h(SmileOutlined, { style: 'color: #108ee9' }),
      });
    }else{
      notification.open({
        message: '提交失败',
        description:'出了一些错误，请通过右侧或下方电话/电子邮箱/二维码的方式联系工作人员',
        icon: () => h(<FrownTwoTone two-tone-color="#ff0000"/>),
      });
    }
  }
}

</script>

<style lang="less">
@media screen and (max-width: 800px) {
  .maskDiv-modal{
    display: none;
  }
  .maskDiv-modal-mobile{
    display: block;
  }
}
@media screen and (min-width: 800px) {
  .maskDiv-modal-mobile{
    display: none;
  }
}

.maskDiv-modal{
  width: 100%;
  min-height: 80vh;
  background: #fff;
  // margin:-375px 0 0 -400px;
  position: relative;
  .maskDiv-modal-back{
    position: absolute;
    top: 85px;
    left: 15px;
    color: #fff;
    font-size: 2rem;

  }
  .maskDiv-modal-header{
    width: 100%;
    height: 75vh;
    background: url('~@image/price/priceShow/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
  }
  .maskDiv-modal-header-text{
    margin-top: 15vh;
    color: #fff;
    font-weight: 650;
    font-size: 4rem;
  }
  .maskDiv-modal-main{
    width: 60vw;
    margin: 0 auto;
    margin-top: -40vh;
    min-height: 35vw;
    .ant-form-item-label label{
      font-weight: 800;
    }
    .collection-create-form_last-form-item .ant-form-item-control-input-content{
      display: flex;
      justify-content: center;
    }
  }
}

</style>

<style lang="less">
.maskDiv-modal-mobile{
  width: 100%;
  min-height: 50vh;
  margin: 80px 0 0 0 ;
  position: relative;
  .maskDiv-modal-back{
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    font-size: 3rem;

  }
  .maskDiv-modal-header{
    width: 100%;
    height: 55vh;
    background: url('~@image/price/priceShow/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
  }
  .maskDiv-modal-header-text{
    margin-top: 10vh;
    color: #fff;
    font-weight: 650;
    font-size: 5rem;
  }
  .maskDiv-modal-main{
    width: 85vw;
    margin: 0 auto;
    margin-top: -30vh;
    min-height: 35vw;
    .ant-form-item-label label{
      font-weight: 800;
    }
    .collection-create-form_last-form-item .ant-form-item-control-input-content{
      display: flex;
      justify-content: center;
    }
  }
}
</style>
