import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import Root from '@/views/root/index.vue'
import Home from '@/views/home/index.vue'
import Example from '@/views/example/index.vue'
import Price from '@/views/price/index.vue'
import Resource from '@/views/resource/index.vue'
import Serve from '@/views/serve/index.vue'
import consultation from '@/views/consultation/index.vue'

const routes = [{
    path: '/',
    name: 'root',
    component: Root,
    redirect: '/home',
    children: [{
        path: '/home',
        name: 'home',
        component: Home,
      },
      {
        path: '/example',
        name: 'example',
        component: Example
      },
      {
        path: '/price',
        name: 'price',
        component: Price
      },
      {
        path: '/resource',
        name: 'resource',
        component: Resource
      },
      {
        path: '/serve',
        name: 'serve',
        component: Serve
      },
      {
        path:'/consultation',
        name :' consultation',
        component:consultation,
        meta: {
          keepAlive: false,
      }
      }
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
