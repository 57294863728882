<template>
  <div class="header-container">
    <div class="header-pc-container header-responsive-container">
      <div class=" header-wrap">
        <div class="header-left">
          <img class="header-left-logo" :src="require('@image/logo.png')" alt="" @click="goHome">
        </div>
        <div class="header-center">
          <Menu></Menu>
        </div>
        <div class="header-phone">
          <img :src="require('@image/phone1.png')">
          <span class="header-phone-number">010-62326117</span>
        </div>
        <div class="header-right">
          <a-button ghost class="header-register" @click="goToRegister">注册</a-button>
          <a-button class="header-login" type="primary" @click="goToLogin">登录</a-button>
          <!-- <question-Circle-Outlined class="header-rightIcon"/> -->
        </div>
      </div>

    </div>
    <div class="header-mobile-container header-responsive-container" :class="visiable? 'header-responsive-container-bgc':''">
      <div class=" header-wrap">
        <div class="header-left">
          <img class="header-mobile-left-logo" :src="require('@public/logo.png')" alt="" @click="goHome">
        </div>
        <div class="header-right">
          <a-button ghost class="header-register" @click="goToRegister">注册</a-button>
          <a-button class="header-login" type="primary" @click="goToLogin">登录</a-button>
          <span><menu-mobile @openOrCloseMenu="openOrCloseMenu"></menu-mobile></span>
          
          <!-- <question-Circle-Outlined class="header-rightIcon"/> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {
    defineComponent,
    ref,
    onMounted,
    onUnmounted,
    watch
  } from 'vue'
  import {
    useRouter
  } from 'vue-router'
  import Menu from './Menu.vue'
  import menuMobile from './menuMobile.vue';
  import {
    QuestionCircleOutlined
  } from '@ant-design/icons-vue';
  import {linkToRegister, linkToLogin} from "@/utils/tool"

  export default defineComponent({
    components: {
      Menu,
      menuMobile,
      QuestionCircleOutlined
    },
    props: {},
    setup() {
      const router = useRouter()
      const goHome = () => {
        router.push('/')
      }
      const goToRegister = linkToRegister
      const goToLogin = linkToLogin
      const openOrCloseMenu = (value) => {
        visiable.value = value
      }


      const scrollTop = ref('')
      const visiable = ref(false) 
      function handleScroll() {
          scrollTop.value =
              window.pageYOffset ||
              document.documentElement.scrollTop ||
              document.body.scrollTop
          scrollTop.value > 650 ? (visiable.value = true) : (visiable.value = false)
      }
      watch(() => router.currentRoute.value.fullPath, (newVal) => {
        if(router.currentRoute.value.fullPath === '/home'){
          window.addEventListener('scroll', handleScroll,true)
          visiable.value = false
        }else{
          window.removeEventListener('scroll', handleScroll,true)
          visiable.value = true
        }
      })
      onMounted(() => {
        if(router.currentRoute.value.fullPath === '/home'){
          window.addEventListener('scroll', handleScroll,true)
        }else{
          visiable.value = true
        }
      })
      onUnmounted(() => {
          window.removeEventListener('scroll', handleScroll,true)
      })

      return {
        goHome,
        goToRegister,
        goToLogin,
        openOrCloseMenu,
        scrollTop,
        visiable,
        handleScroll,
      }
    }
  })

</script>

<style scoped>
  @media screen and (max-width: 800px) {
    .header-container{
      background: rgba(0, 0, 0, 0.2)
    }
    .header-pc-container {
      display: none;
    }

    .header-mobile-container {
      display: block;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 900px) {
    .header-right{
      width: 200px !important;
      padding-left: 0px !important;
    }
  }

  @media screen and (min-width: 800px) {
    .header-container{
      background: #FFFFFF;
    }
    .header-pc-container {
      display: block;
    }

    .header-mobile-container {
      display: none;
    }
  }

  .header-container {
    position: fixed;
    top: 0;
    width: 100%;
    margin: auto;
    height: 80px;
    box-shadow: 0px 2px 8px 0px rgba(0, 147, 147, 0.1);
    z-index: 16;
  }

  .header-responsive-container {
    width: 100%;
    height: 100%;
  }
  .header-responsive-container-bgc{
    background-color: #FFFFFF;
  }

  .header-wrap {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  .header-left {
    /* width: 190px; */
    padding-left: 15px;
    padding-top: 9px;
  }

  .header-left-logo {
    cursor: pointer;
    height: 42px;
    margin-top: 10px;
  }
  .header-mobile-left-logo{
    cursor: pointer;
    height: 42px;
    margin-top: 10px;
    border-radius: 3px;
  }

  .header-center {
    flex: 1;
  }
  .header-phone{
    padding-top: 29px;
  }
  .header-phone-number{
    margin-left: 5px;
  }

  .header-right {
    width: 250px;
    padding-top: 24px;
    padding-left: 30px;
  }

  .header-right .header-login {
    width: 80px;
  }

  .header-right .header-register {
    margin-right: 10px;
    width: 80px;
    color: #23C5D0;
    border-radius: 5px;
    border-color: #23C5D0;
  }

  .header-right .header-login {
    border-radius: 5px;
    border-color: #23C5D0;
    background-color: #23C5D0;
  }

  .header-right .header-register:hover {
    background-color: #23C5D0;
    border-color: #23C5D0;
    color: white;
    opacity: 0.9;
  }


  .header-right .header-login:hover {
    opacity: 0.9;
  }

  .header-rightIcon {
    font-size: 18px;
    margin-left: 15px;
    color: gray;
    cursor: pointer;
  }

</style>

<style>

</style>
