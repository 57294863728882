<template>
  <div class="header-menu-mobile-container">
    <div class="header-menu-mobile-tool">
      <span class="iconfont icon-liebiao header-menu-mobile-more"
        :class="state.openMenu ? 'header-menu-mobile-open-menu' : ''" @click="openOrCloseMenu"></span>
    </div>

    <div class="header-menu-mobile-content-container" v-if="state.openMenu" @touchmove.prevent @mousewheel.prevent>
      <div class="header-menu-mobile-navigation-item"
        :class="state.active === item.id ? 'header-menu-mobile-navigation-item-active' : ''" v-for="item in state.menu"
        :key="item.id">
        <div v-if="!item.children" @click="linkTo(item)">{{ item.name }}</div>
        <a-dropdown :trigger="['click']" v-if="item.id === 'resource'" style="left: 30px;">
          <div class="header-navigation-item-dropdown" @click.prevent>
          </div>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0" @click="resourceJump('video')">
                <a>视频中心</a>
              </a-menu-item>
              <a-menu-item key="1" @click="resourceJump('help')">
                <a>帮助中心</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-menu v-if="item.children" style="width: 100%" mode="inline" @click="handleClick">
          <a-sub-menu :key="item.id" @titleClick="titleClick(item)" class="towMenu">
            <template #title>{{ item.name }}</template>
            <a-sub-menu v-for="citem in item.children" :key="citem.id" :title="citem.name">
              <a-menu-item v-for="ccitem in citem.children" :key="ccitem.id">{{ ccitem.name }}</a-menu-item>
            </a-sub-menu>
          </a-sub-menu>
        </a-menu>
      </div>
    </div>

  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  watch,
  defineEmits
} from 'vue'
import {
  useRouter,
  onBeforeRouteUpdate
} from 'vue-router'
import {
  MenuOutlined
} from "@ant-design/icons-vue"
import {
  NAV_CONFIG
} from "@/utils/PAGE-CONFIG.js"

export default defineComponent({
  name: 'Menu',
  components: {
    MenuOutlined
  },
  props: {},
  emits: ['openOrCloseMenu'],
  setup(props, ctx) {
    const router = useRouter()
    let state = reactive({
      menu: NAV_CONFIG,
      active: 'home',
      openMenu: false,
    })

    watch(() => router.currentRoute.value.name, (newValue, oldValue) => {
      state.active = newValue
    }, {
      immediate: true
    })

    watch(() => props.active, (newVal) => {
      state.active = newVal
    })

    const linkTo = (item) => {
      state.active = item.id
      item.path && router.push(item.path)
      state.openMenu = false
    }

    const titleClick = (item) => {
      state.active = item.id
    }
    const handleClick = () => {
    }

    const openOrCloseMenu = () => {
      state.openMenu = !state.openMenu
      ctx.emit('openOrCloseMenu', state.openMenu)
    }
    const resourceJump = (id) => {
      if (id === 'video') {
        const path = '/resource'
        router.push(path)
        state.openMenu = false
      } else if (id === 'help') {
        const link = 'https://www.yuque.com/ctkj/cgscloud?# 《cgscloud》'
        link && window.open(link)
      }
    }

    return {
      state,
      linkTo,
      openOrCloseMenu,
      titleClick,
      handleClick,
      resourceJump,
    }
  }
})

</script>

<style scoped>
.header-menu-mobile-container {
  display: inline-block;
  margin: auto 10px;
}

.header-menu-mobile-tool {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-menu-mobile-more {
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}


.header-menu-mobile-open-menu {
  color: #23C5D0;
}

.header-menu-mobile-content-container {
  position: fixed;
  z-index: 16;
  top: 79px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #fff;
  ;
  width: calc(100% + 1px);
}

.header-menu-mobile-navigation-item {
  font-size: 15px;
  font-weight: 600;
  line-height: 53px;
  /* border-bottom: 1px solid #DDDDDD; */
  cursor: pointer;
}

.header-menu-mobile-navigation-item>div {
  padding: 0 10px;
}

.header-menu-mobile-navigation-item-active {
  color: #23C5D0;
  /* border-color: #23C5D0; */
}

.header-navigation-item-dropdown {
  height: 53px;
  width: 100%;
  position: absolute;
  top: 210px;
}

.ant-dropdown-content .ant-dropdown-menu {
  margin-top: -20px;
  margin-left: 30px;
}
</style>

<style>
.towMenu .ant-menu-submenu-title {
  padding-left: 10px !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 53px;
}

.towMenu .ant-menu-submenu {
  padding-left: 38px !important;
}

.towMenu .ant-menu-submenu-selected {
  color: #08979C !important;
}

.towMenu .ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #08979C !important;
}

.towMenu .ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}

.towMenu .ant-menu-item-selected {
  color: #08979C !important;
  ;
}

.towMenu .ant-menu-item {
  padding-left: 50px !important;
}

.towMenu .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
  color: #08979C !important;
}

.towMenu .ant-menu-item-only-child {
  font-size: 2.6rem;
  font-weight: 400;
  color: #6B7487;
}
</style>
