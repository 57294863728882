<template>
  <div class="example-show-container">
    <div class="example-show-bg-container">
      <div class="example-show-bg-banner"></div>
    </div>
    <portal-content-layout :senceList="showList" :allSenceList="allClassifyList" :default-img="require('@image/sceneCover.jpg')"
      :categoryList="state.categoryList" :menu="state.menu" :tags="state.tags" v-model:activeTags="state.activeTags"
      v-model:showCategory="state.showCategory" @enterDEMO="enterDEMO" @enterVideo="enterVideo">
    </portal-content-layout>
    
    <video-modal :visible="videoState.visible" :path="videoState.path" :scene-path="videoState.scenePath" @close="closeVideoModal"></video-modal>
  </div>
</template>

<script>
  import {
    computed,
    defineComponent,
    reactive
  } from 'vue'
  import portalContentLayout from './portalContentLayout.vue';
  import videoModal from './videoModal.vue'
  import {
    getClassify,
    getExampleList,
    getTags
  } from '@/api/example'

  export default defineComponent({
    name: 'example',
    components: {
      portalContentLayout,
      videoModal
    },
    setup() {
      const state = reactive({
        categoryList: [],
        showCategory: '',
        exampleList: [],
        menu: [],
        tags: [],
        activeTags: []
      })
      const videoState = reactive({
        visible: false,
        path: '',
        scenePath: ''
      })

      const showList = computed(() => {
        return state.exampleList.filter(_item => {
          let _bool = _item.classify === state.showCategory
          if (state.activeTags.length > 0) {
            let _isTagIn = false
            if (_item.tags && _item.tags.length > 0) {
              for (let i = 0; i < _item.tags.length; i++) {
                if (state.activeTags.includes(_item.tags[i].toString())) {
                  _isTagIn = true
                  break
                }
              }
            }
            _bool = _bool && _isTagIn
          }
          return _bool
        })
      })

      const allClassifyList = computed(() => {
        return state.exampleList.filter(_item => _item.classify === state.showCategory)
      })

      let _setCategoryList = async () => {
        try {
          let _result = await getClassify()
          if (_result.code === 200) {
            let _list = _result.data.filter(_item => !!_item.isShow)
            _list.sort((a, b) => a.sort - b.sort)
            state.categoryList = _list.map(_item => {
              let icon = ''
              if (_item.img && _item.img.length > 0 && _item.img[0].url) {
                icon = _item.img[0].url
              }
              return {
                id: _item.id,
                title: _item.label,
                icon: icon
              }
            })
            if (state.categoryList.length > 0) {
              state.showCategory = state.categoryList[0].id
            }
          }
        } catch (err) {
          console.error(err)
        }
      }
      let _setExampleList = async () => {
        try {
          let _result = await getExampleList()
          if (_result.code === 200) {
            let _list = _result.data.filter(_item => !!_item.isShow)
            _list = _list.map(_item => {
              let _img = ''
              if (_item.coverImage && _item.coverImage.length > 0 && _item.coverImage[0].url) {
                _img = _item.coverImage[0].url
              }
              _item.img = _img
              return _item
            })
            state.exampleList = _list
          }
        } catch (err) {
          console.error(err)
        }
      }
      let _setTags = async () => {
        try {
          let _result = await getTags()
          if (_result.code === 200) {
            let _list = _result.data
            state.tags = _list
          }
        } catch (err) {
          console.error(err)
        }
      }
      _setCategoryList()
      _setExampleList()
      _setTags()
      let enterDEMO = (data) => {
        let _linkScenePath = data.linkScenePath
        if (_linkScenePath) {
          window.open(_linkScenePath, '_blank')
        }
      }
      let enterVideo = (data) => {
        videoState.path = data.videoPath
        videoState.scenePath = data.linkScenePath
        videoState.visible = true
      }
      let closeVideoModal = () => {
        videoState.visible = false
      }
      return {
        state,
        showList,
        allClassifyList,
        videoState,
        enterVideo,
        enterDEMO,
        closeVideoModal
      }
    }
  })

</script>
<style scoped>
  .example-show-container {
    /* max-width: 1180px;
    margin: 0 auto; */
    position:relative;
  }
  
  .example-show-bg-container{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
  }
  .example-show-bg-banner{
    width: 100%;
    height: 703px;
    background-image: url(~@image/example/example-banner-bg1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 100% ;
    
  }

</style>

<style>
  /* .example-show-container .ant-tabs-top>.ant-tabs-nav {
    margin: 0 auto 16px auto;
  } */

</style>
