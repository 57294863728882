<template>
  <div class="header-menu">
    <div class="header-navigation-item" :class="state.active === item.id ? 'header-navigation-item-active' : ''"
      v-for="item in state.menu" :key="item.id" @click="linkTo(item)" @mouseover="towMenuShow(item)">
      <div>{{ item.name }}</div>
      <a-dropdown :trigger="['click']" v-if="item.id === 'resource'">
        <div class="header-navigation-item-dropdown" @click.prevent>
        </div>
        <template #overlay>
          <a-menu>
            <a-menu-item key="0" @click="resourceJump('video')">
              <a>视频中心</a>
            </a-menu-item>
            <a-menu-item key="1" @click="resourceJump('help')">
              <a>帮助中心</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <div class="header-navigation-item-towMenu" v-if="state.towMenuShow" @mouseleave="removeTowMenuShow()">
      <div class="towMenu-container">
        <div v-for="item in state.towMenu" :key="item.id">
          <div class="towMenu-oneMenu"> {{ item.name }} </div>
          <div v-for="twoItem in item.children" :key="twoItem.id" class="towMenu-towMenu">
            {{ twoItem.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  watch
} from 'vue'
import {
  useRouter,
} from 'vue-router'
import {
  DownOutlined
} from '@ant-design/icons-vue';
import {
  NAV_CONFIG
} from "@/utils/PAGE-CONFIG.js"

export default defineComponent({
  name: 'Menu',
  components: {
    DownOutlined
  },
  props: {},
  setup(props) {
    const router = useRouter()
    let state = reactive({
      menu: NAV_CONFIG,
      active: 'home',
      towMenu: [],
      towMenuShow: false,
    })
    watch(() => router.currentRoute.value.name, (newValue, oldValue) => {
      state.active = newValue
    }, {
      immediate: true
    })

    watch(() => props.active, (newVal) => {
      state.active = newVal
    })

    const linkTo = (item) => {
      state.active = item.id
      if (item.id === 'resource') {

      } else if (item.path && item.path !== '') {
        router.push(item.path)
      } else {
        item.link && window.open(item.link)
      }
    }
    const towMenuShow = (item) => {
      state.towMenuShow = false
      if (item.children) {
        state.towMenuShow = true
        state.towMenu = item.children
      }
    }
    const removeTowMenuShow = () => {
      state.towMenuShow = false
    }
    const resourceJump = (id) => {
      if (id === 'video') {
        const path = '/resource'
        router.push(path)
      } else if (id === 'help') {
        const link = 'https://www.yuque.com/ctkj/cgscloud?# 《cgscloud》'
        link && window.open(link)
      }
    }
    return {
      state,
      linkTo,
      towMenuShow,
      removeTowMenuShow,
      resourceJump,
    }
  }
})

</script>

<style scoped>
  @media screen and (min-width: 800px) and (max-width: 900px) {
    .header-menu{
      margin-left: 0px !important;
    }
  }
:global(.ant-dropdown-content .ant-dropdown-menu) {
  border-radius: 10px ;
  width: 200px;
  height: 100px;
  margin-top: 10px;
  padding-top: 10px;
}
:global(.ant-dropdown-content .ant-dropdown-menu .ant-dropdown-menu-item) {
  width: 200px;
  height: 40px;
  font-size: 16px;
}
:global(.ant-dropdown-content .ant-dropdown-menu .ant-dropdown-menu-item-active) {
  color: #23C5D0;
}

.header-menu {
  margin-left: 30px;
  display: flex;
  justify-content: flex-start;
  height: 100%;
}

.header-navigation-item {
  margin: auto 16px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}

.header-navigation-item-dropdown {
  height: 25px;
  width: 32px;
  position: absolute;
  top: 0;
}

.header-navigation-item-towMenu {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(5px);
  /* opacity: 0.9; */
  padding-bottom: 40px;
  animation: slideContentUp 0.3s linear both;
  overflow: hidden;
  /* animation: slideContentDown 0.3s linear both; */
}

.towMenu-container {
  display: flex;
  margin-left: 225px;
}

.towMenu-oneMenu {
  width: 250px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 19px;
  margin: 30px 0;
}

.towMenu-towMenu {
  font-size: 14px;
  font-weight: 400;
  color: #6B7487;
  line-height: 16px;
  margin-bottom: 10px;
}

/* 动态设置高度 */
@keyframes slideContentUp {
  from {
    height: 0;
  }

  to {
    height: 450px;
  }
}

.header-navigation-item:hover {
  font-weight: 700;
  color: #23C5D0;
}

.header-navigation-item-active {
  font-weight: 700;
  color: #23C5D0;
}

.header-navigation-item::after {
  position: absolute;
  bottom: -12px;
  left: 50%;
  width: 36px;
  height: 0px;
  transition: height 0.2s;
  border-radius: 2px;
  content: "";
  background: #23C5D0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* .header-navigation-item:hover:not(.header-navigation-item-active)::after, */
.header-navigation-item-active::after {
  height: 3px;
}
</style>
