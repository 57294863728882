<template>
  <div class="resource-resourceBanner-container">
    <img src="@image/videoBanner.png" class="resource-resourceBanner-container-img">
    <a-input class="resource-resourceBanner-search" v-model:value="value" :placeholder=placeholder size="large"
      style="border-radius: 20px;" @keyup.enter="onSearch" @change="inputChange">
      <template #prefix>
        <!-- <div class="resource-resourceBanner-search-icon" @click="onSearch"></div> -->
        <img src="@image/search.png" width="20" height="20" class="resource-resourceBanner-search-icon" @click="onSearch">
      </template>
    </a-input>
  </div>
</template>
  
<script setup>
import { ref } from 'vue';
const placeholder = ref('输入关键字搜索视频教程')
const value = ref()
const emit = defineEmits(['placeholder'])
const onSearch = () => {
  emit('placeholder', value.value)
}
const inputChange = () => {
  emit('inputChange', value.value)
}
</script>
  
<style scoped>
.resource-resourceBanner-container {
  position: relative;
  margin-top: 80px;
}

.resource-resourceBanner-container-img {
  width: 100%;
}

.resource-resourceBanner-search {
  position: absolute;
  top: 60%;
  left: 12%;
}

.resource-resourceBanner-search {
  width: 480px;
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
  .resource-resourceBanner-container-img {
    width: 1300px;
  }
}

@media screen and (max-width: 800px) {
  .resource-resourceBanner-container-img {
    width: 1300px;
  }

  .resource-resourceBanner-search {
    left: 8%;
    width: 400px;
  }
}

:deep(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
  border-color: #23C5D0;
}

:deep(.ant-input-affix-wrapper:hover) {
  border-color: #23C5D0;
}

:deep(.ant-input-affix-wrapper-focused) {
  border-color: #23C5D0;
}

.resource-resourceBanner-search>>>.ant-input-group>.ant-input:first-child {
  border-radius: 20px;
}
</style>
