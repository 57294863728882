<template>
  <a-modal v-model:visible="vis" :title="title" :footer="null" @ok="handleOk" @cancel="handleCancel" destroy-on-close
    style="width: 300px;">
    <div class="contact-buy-container">
      <div>电话：010-62326117</div>
      <div>QQ技术交流：813165113</div>

      <div class="contact-buy-qccode">
        <div class="contact-buy-qccode-wrap">
          <img :src="require('@image/QRCode/qqqun.png')" alt="QQ群" />
        </div>
        <span class="contact-buy-qccode-title">畅图云QQ群</span>
      </div>
    </div>
    <!-- <template #footer>
      <a-button ghost class="video-model-close-button" @click="handleCancel">关闭</a-button>
    </template> -->
  </a-modal>
</template>

<script>
  import {
    ref,
    reactive,
    defineComponent,
    watch,
    getCurrentInstance
  } from 'vue'

  export default defineComponent({
    components: {},
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      path: {
        type: String,
        default: ''
      },
      scenePath: {
        type: String,
        default: ''
      }
    },
    setup(props, ctx) {
      let vis = ref(props.visible)
      watch(() => props.visible, (newValue) => {
        vis.value = newValue
      })
      const handleOk = () => {
        close()
      }
      const handleCancel = () => {
        close()
      }
      const close = () => {
        ctx.emit('close')
      }
      return {
        vis,
        handleOk,
        handleCancel,
      }
    }
  });

</script>

<style scoped>
  .contact-buy-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
  }
  .contact-buy-qccode{
    margin-top: 50px;
    text-align: center;
  }
  .contact-buy-qccode-title{
    font-size: 14px;
    font-weight: 500;
  }

</style>

<style>
  .video-model-close-button.ant-btn.ant-btn-background-ghost {
    margin-right: 10px;
    width: 80px;
    color: #08979C;
    border-radius: 5px;
    border-color: #08979C;
  }

  .video-model-close-button.ant-btn.ant-btn-background-ghost:hover {
    background-color: #08979C;
    border-color: #08979C;
    color: white;
    opacity: 0.9;
  }

</style>
