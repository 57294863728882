<template>
  <div class="example-container">
    <show></show>
    <footer-banner></footer-banner>
    <!-- <div class="example-container"></div> -->
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive
  } from 'vue'
  import show from './show.vue';
  import FooterBanner from './footerBanner.vue'

  export default defineComponent({
    name: 'example',
    components: {
      show,
      FooterBanner
    },
    setup() {
      return {}
    }
  })

</script>
<style scoped>
  .example-container {
    min-height: 100%;
    position:relative;
  }

</style>
