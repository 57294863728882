<template>
  <div style="text-align:center;" :style="{background: `url(${require('@image/scene-bg.png')})`, 'background-size': 'cover'}" class="scene-pc">
    <span class="home-scene-title">典型场景</span>
    <div class="home-scene-cards">
      <a-card hoverable class="home-scene-card" v-for="item in cardsList" :key="item.id">
        <template #cover>
          <div>
            <img class="card-img" alt="example" :src="item.imgUrl" />
          </div>
        </template>
        <a-card-meta>
          <template #title>
            <span class="card-title">{{item.title}}</span>
          </template>
          <template #description>
            <span class="card-text card-text-mobile">{{item.text}}</span>
          </template>
        </a-card-meta>
      </a-card>

      
      <a-card hoverable class="home-scene-card" v-for="item in cardsList" :key="item.id">
        <template #cover>
          <div>
            <img class="card-img" alt="example" :src="item.imgUrl" />
          </div>
        </template>
        <a-card-meta>
          <template #title>
            <span class="card-title">{{item.title}}</span>
          </template>
          <template #description>
            <span class="card-text card-text-mobile">{{item.text}}</span>
          </template>
        </a-card-meta>
      </a-card>
    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
    onMounted
  } from 'vue'
  export default defineComponent({
    setup() {
      let cardsList = reactive([{
          id: '1',
          title: '智慧园区',
          text: '结合园区规划GIS、CAD等基础空间数据资料，快速搭建智慧园区一张图，实现视频监控、经济指标、智慧场景对接，实现根据实时监测数据、填报数据进行动态更新',
          imgUrl: require('@image/scene/image1.png')
        },
        {
          id: '2',
          title: '轨道交通',
          text: '融合BIM、地质、物探、点云、GIS模型与数据，搭建施工运维数字孪生环境 ',
          imgUrl: require('@image/scene/image2.png')
        },
        // {
        //   id: '3',
        //   title: '智慧社区',
        //   text: '融合BIM、地质、物探、点云、GIS模型与数据，搭建施工运维数字孪生环境 。实现数据采集、数据可视化、数据分析与决策，实时远程了解施工进度情况、施工安全情况，运筹帷幄。',
        //   imgUrl: require('@image/scene/image3.png')
        // },
        {
          id: '4',
          title: '智慧工地',
          text: '结合BIM、GIS、三维地质建模、CAD等基础空间数据资料，快速搭建智慧工地地上下一体化一张图，实现数据采集、数据可视化、数据分析与决策 ',
          imgUrl: require('@image/scene/image4.png')
        },
        {
          id: '5',
          title: '矿山',
          text: '对矿山进行三维数字重建，集成物联网数据，实现煤矿综采、综掘的模拟与实时监控，结合地质、钻井、物探等数据实现矿井的一体化管控',
          imgUrl: require('@image/scene/image5.png')
        },
        {
          id: '6',
          title: '安全检测',
          text: '结合项目实际需求，对硬件数据进行实时采集，基于零代码功能快速搭建安全监测系统，实现数据采集、数据可视化、数据报警、数据分析等功能',
          imgUrl: require('@image/scene/image6.png')
        },
        {
          id: '7',
          title: '地质',
          text: '钻孔、地质雷达、电磁、电法、地震多源数据融合建模。轻量化模型一键上传云端发布，web、移动端实时查看分析地质风险，辅助科学决策。',
          imgUrl: require('@image/scene/image7.png')
        },
        {
          id: '8',
          title: '规划设计',
          text: '海量倾斜摄影测量数据加载，设计方案立体展示，实际规划设计参照',
          imgUrl: require('@image/scene/image8.png')
        },
        {
          id: '9',
          title: '海洋',
          text: '多源数据融合,水面、水中、水下地形,体渲染,态势感知',
          imgUrl: require('@image/scene/image9.png')
        }
      ])
      const clickitem = () => {
        console.log("dasdsd")
      }
      return {
        cardsList,
        clickitem
      }
    }
  })

</script>

<style lang="less" scoped>
  .home-scene-title {
    font-family: "SourceHanSansCN-Bold";
    font-size: 4.8rem;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    color: #333333;
  }

  .home-scene-cards:hover {
    animation-play-state: paused;
    -webkit-animation-play-state: paused
  }

  .home-scene-cards {
    margin-top: 8rem;
    // height: 51rem;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;

    display: flex;
    width: fit-content;
    /* width: 3824px; */
    animation: scene-rolling 60s linear infinite;

    .home-scene-card {
      margin-right: 3rem;
      margin-bottom: 3rem;
      // height: 41.4rem;
      opacity: 1;
      background: #FFFFFF;
      overflow: hidden;
      // box-shadow: 0px 8px 12px 0px rgba(13, 126, 115, 0.1);

      .card-img {
        // border-radius: 15px;
        width: 100%;
        height: 100%;
      }

      .card-title {
        font-family: "SourceHanSansCN-Bold";
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 3.2rem;
        color: #333333;
      }

      .card-text {
        font-family: "SourceHanSansCN-Normal";
        font-size: 1.4rem;
        font-weight: 350;
        color: #666666;
      }
    }
    .home-scene-card:hover{
      box-shadow: 0px 8px 12px 0px rgba(13, 126, 115, 0.1);
    }
  }

  .home-scene-cards::-webkit-scrollbar {
    display: none;
  }

  @keyframes scene-rolling {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
    }

    to {
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%)
    }
  }

  @media screen and (max-width: 800px){
    .home-scene-title{
      font-size: 8rem;
    }
    .home-scene-card{
      border-radius: 5px;
      height: 61rem;
      width: 56rem;
    }
    .scene-pc{
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }
  @media screen and (max-width: 1000px){
    // .card-text-mobile{
    //   display: none;
    // }
  }
  @media screen and (min-width: 800px){
    .scene-pc{
      margin-top: 120px;
      padding: 100px;
    }
    .home-scene-card{
      height: 41.4rem;
      width: 41.2rem;
      border-radius: 10px;
    }
  }

</style>

<style>
.home-scene-cards .ant-card-body{
  padding: 2.4rem;
}
</style>
