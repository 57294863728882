<template>
  <div class="price-useDetail-container-mobile">
    <div class="price-useDetail-table">
      <div class="price-useDetail-table-top">
        <span><img src="@image/price/useDetail/check_true.png" style="margin-right:8px">完整包含</span>
        <span><img src="@image/price/useDetail/check.png" style="margin-right:8px">部分包含</span>
        <span><img src="@image/price/useDetail/check_false.png" style="margin-right:8px">不包含</span>
      </div>
      <div style="clear:both"></div>
      <br>
      <a-carousel arrows :before-change="afterChange" class="price-useDetail-container-mobile-swi">
        <template #prevArrow>
          <div class="custom-slick-arrow" style="position: absolute;left: 20px;top:100vh;z-index: 1">
            <left-circle-outlined />
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-slick-arrow" style="position: absolute;right: 30px;top:100vh">
            <right-circle-outlined />
          </div>
        </template>
        <div v-for="item in detailList" :key="item.id">
          <div class="price-useDetail-table-title">
            <!-- <div>版本明细</div> -->
            <div style="margin-right: 22px;">{{item.title}}</div>
          </div>
          <a-collapse :default-active-key="state.priceActiveKeyList" :bordered="false" style="margin-top:3.2rem" @change="changeIcon">
               <a-collapse-panel :key="priceObj.key" :style="customStyle" v-for="priceObj in config" :showArrow="false">
                <template #header>
                  <div class="useDetail-table-header" style="color:black;">
                    <div class="price-use-detail-version-detail-table-header">
                      <span>{{priceObj.header.title}}</span>
                    </div>
                  </div>
                </template>
                <template #extra>
                  <caret-right-outlined :rotate="currentIconNum.includes(priceObj.key) ? 90 : 180" style="font-size:16px;margin-top:6px" />
                </template>
                <div class="useDetail-table-content1">
                  <a-collapse class="price-use-detail-level" v-model:activeKey="state.levelActiveKeyMap[priceObj.key]"
                    :bordered="false">
                    <a-collapse-panel class="price-use-detail-level-item" :style="customStyle" :key="levelObj.header.key"
                      v-for="(levelObj) in priceObj.level" :showArrow="false">
                      <template #header>
                        <div class="price-use-detail-level-header">
                          {{ levelObj.header.title }}
                        </div>
                      </template>
                      <template #extra>
                        <caret-right-outlined :rotate="state.levelActiveKeyMap[priceObj.key].includes(levelObj.header.key) ? 90 : 180" style="font-size:16px;margin-top:6px" />
                      </template>

                      <div class="price-use-detail-version-detail-container" v-for="(detailObj, index) in levelObj.detail"
                        :key="index" :style="detailObj.valueList.length === 0 ? secondHeadTitle : ''">
                        <!-- <div class="cleanDiv"></div> -->
                        <div class="price-use-detail-version-detail-table-body-header">
                          <span>{{detailObj.title}}</span>
                        </div>
                        <div class="price-use-detail-version-detail-table-body-content">
                          <!-- <div v-for="(detailValueObj, _ind) in detailObj.valueList" :key="_ind"> -->
                            <img v-if="detailObj.valueList[currentNum].iconable" :src="imgMap[detailObj.valueList[currentNum].value]">
                            <span v-else>{{detailObj.valueList[currentNum].value}}</span>
                          <!-- </div> -->
                        </div>
                      </div>

                      </a-collapse-panel>
                    </a-collapse>
                  <!-- <div class="price-use-detail-version-detail-container" v-for="(detailObj, index) in priceObj.level"
                    :key="index">
                    <div class="price-use-detail-version-detail-table-body-header">
                      <span>{{detailObj.header.title}}</span>
                    </div>
                    <div class="price-use-detail-version-detail-table-body-content">
                      <div>
                        <img v-if="detailObj.valueList[item.id].iconable" :src="imgMap[detailObj.valueList[item.id].value]">
                        <span v-else>{{detailObj.valueList[item.id].value}}</span>
                      </div>
                    </div>
                  </div> -->
                  <!-- <a-divider dashed style="margin:0" /> -->
                </div>
              </a-collapse-panel>
          </a-collapse>
        </div>
      </a-carousel>
    </div>
    <div class="price-useDetail-tip">
      <div class="useDetail-tip-text1">额外说明：</div>
      <div class="useDetail-tip-text2" style="margin-top:10px">1.免费版仅限用户探索，不能用于商业活动。</div>
      <div class="useDetail-tip-text2">2.如果现有功能无法满足您的需求，我们提供定制开发服务。</div>
    </div>
  </div>
</template>

<script>
  import {
    CaretRightOutlined,
    LeftCircleOutlined,
    RightCircleOutlined
  } from '@ant-design/icons-vue';
  import {
    defineComponent,
    reactive,
    ref
  } from 'vue'
   import {PRICE_DETAIL_CONFIG} from '@/utils/PAGE-CONFIG.js'

  export default defineComponent({
    name: 'price',
    components: {
      CaretRightOutlined,
      LeftCircleOutlined,
      RightCircleOutlined
    },
    setup() {
      const state = reactive({
        priceActiveKeyList: [],
        levelActiveKeyMap: {}
      })

      const customStyle = 'background: rgba(0, 170, 159, 0.05);border-radius: 4px;overflow: hidden;border: 0';
      const imgMap = {
        0: require('@image/price/useDetail/check_false.png'),
        1: require('@image/price/useDetail/check.png'),
        2: require('@image/price/useDetail/check_true.png')
      }
      let detailList = reactive([{
          id: 0,
          title: '免费版',
        },
        // {
        //   id: 1,
        //   title: '单应用版',
        // },
        {
          id: 2,
          title: '标准版',
        },
        {
          id: 3,
          title: '专业版',
        },
      ])
      const currentNum = ref(0)
      const currentIconNum = ref(state.priceActiveKeyList)
      const config = JSON.parse(JSON.stringify(PRICE_DETAIL_CONFIG))
      const secondHeadTitle = 'background: rgba(0, 170, 159, 0.05)'
      for (let i = 0; i < config.length; i++) {
        let priceObj = config[i]
        state.priceActiveKeyList.push(priceObj.key)
        let _levelList = priceObj.level
        state.levelActiveKeyMap[priceObj.key] = []
        for (let j = 0; j < _levelList.length; j++) {
          let _levelObj = _levelList[j]
          let _header = _levelObj.header
          state.levelActiveKeyMap[priceObj.key].push(_header.key)
        }
      }

      const afterChange = (from, to) => {
        currentNum.value = to
      }

      const changeIcon = (key) => {
        currentIconNum.value = key
      }

      return {
        customStyle,
        detailList,
        imgMap,
        config,
        secondHeadTitle,
        state,
        afterChange,
        currentNum,
        changeIcon,
        currentIconNum
      }
    }
  })

</script>
<style lang="less" scoped>
  .price-useDetail-container-mobile {
    margin-top: 50px;
    position: relative;
    .price-useDetail-table {
      width: 95%;
      // height: 974px;
      margin: auto;

      .custom-slick-arrow {
        width: 25px;
        height: 25px;
        font-size: 30px;
        color: gray;
        z-index: 1;
        margin-top: -796px;
      }

      .price-useDetail-table-top {
        // display: flex;
        // justify-content: space-between;
        float: right;

        span {
          margin-left: 62px;
          font-family: 'SourceHanSansCN-Regular';
          font-size: 16px;
          font-weight: normal;
          line-height: 18px;
          color: #3D3D3D;
        }
      }

      .price-useDetail-table-title {
        margin-top: 15px;
        text-align: center;

        div {
          display: block;
          font-family: 'SourceHanSansCN-Regular-Regular';
          font-size: 32px;
          font-weight: normal;
          line-height: 84px;
          color: #252525;
        }
      }

      .useDetail-table-header {
        font-family: 'SourceHanSansCN-Regular';
        font-size: 18px;
        font-weight: 500;
        color: #252525;
        display: flex;
        flex: 1;
        justify-content: space-between;
      }

      .useDetail-table-content1 {
        width: 100%;
        // height: 178px;
        background-color: white;

        .useDetail-table-row {
          height: 56px;
          display: flex;
          justify-content: space-between;

          div:nth-child(1) {
            text-align: left;
          }

          div {
            margin-right: 16px;
            font-family: 'SourceHanSansCN-Regular';
            font-size: 16px;
            font-weight: normal;
            line-height: 56px;
            text-align: right;
            color: #666666;
          }
        }
      }

      .useDetail-table-content2 {
        width: 100%;
        height: 234px;
        background-color: white;

        .useDetail-table-row {
          // width: 91%;
          height: 56px;
          display: flex;
          justify-content: space-between;

          div {
            font-family: 'SourceHanSansCN-Regular';
            font-size: 16px;
            font-weight: normal;
            line-height: 56px;
            text-align: left;
            color: #666666;
          }
        }
      }
    }

    .price-useDetail-tip {
      width: 95%;
      margin: auto;
      margin-top: 80px;
      padding: 16px 24px;
      background: #F8FFFE;
      box-sizing: border-box;
      border: 1px solid #D5E4E2;

      .useDetail-tip-text1 {
        font-family: 'SourceHanSansCN-Regular';
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        color: #000000;
      }

      .useDetail-tip-text2 {
        font-family: 'SourceHanSansCN-Regular';
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        color: #666666;
      }
    }
  }

  .price-use-detail-version-detail-container {
    height: 56px;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }

  .price-use-detail-version-detail-table-header {
    width: 235px;
  }

  .price-use-detail-version-detail-table-header-content {
    // flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .price-use-detail-version-detail-table-header-content>div {
    // width: 25%;
    padding-right: 20px;
    text-align: center;
  }

  .price-use-detail-version-detail-table-body-header {
    width: 230px;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  .price-use-detail-version-detail-table-body-header>span {
    font-size: 16px;
    color: #666666;
    letter-spacing: 0px;
  }

  .price-use-detail-version-detail-table-body-content {
    // flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .price-use-detail-version-detail-table-body-content>div {
    // width: 25%;
    padding-right: 20px;
    text-align: center;
    font-family: SourceHanSansCN-Regular;
  }

  @media screen and (max-width: 800px) {
    .price-useDetail-container-mobile {
      .price-useDetail-table {
        .price-useDetail-table-top {
          float: none;
          margin: auto;

          span {
            margin-left: 30px;
            font-family: 'SourceHanSansCN-Regular';
            font-size: 16px;
            font-weight: normal;
            line-height: 18px;
            color: #3D3D3D;
          }
        }

        .price-useDetail-table-title {
          margin-left: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 500px) {
    .price-useDetail-container-mobile {
      margin-top: 50px;

      .price-useDetail-table {
        .useDetail-table-content2 {
          .useDetail-table-row {
            // width: 90%;
          }
        }
      }
    }
  }

</style>
<style>
  .price-useDetail-container-mobile .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
  }

  .price-useDetail-container-mobile .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    width: 100%;
    justify-content: space-between;
  }

  .price-useDetail-container-mobile .price-useDetail-table .custom-slick-arrow::before {
    content: none;
  }
</style>
<style lang="less">
  // .price-useDetail-container-mobile .ant-tabs-ink-bar {
  // 	background: #08979C;
  // }

  // .price-useDetail-container-mobile .ant-tabs-tab:hover .home-features-text {
  // 	color: #08979C;
  // }

  // .price-useDetail-container-mobile .ant-carousel .slick-dots-bottom{
  // 	bottom: -30px;
  // }

  // .price-priceShow-container-mobile .ant-carousel .slick-dots {

  // 	button {
  // 		background-color: rgb(219, 215, 215);
  // 		border-radius: 25px;
  // 		width: 1.5rem;
  // 		height: 1.5rem;
  // 	}

  // 	.slick-active button {
  // 		width: 3.5rem;
  // 		background-color: #08979C;
  // 	}
  // }

  // .price-useDetail-container-mobile-swi .slick-initialized{
  //   position: relative;
  // }
  .price-useDetail-container-mobile .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content{
    background: #fff;
  }
</style>
