<template>
  <div class="partner-container">
    <div class="partner-conveyer-belt-container">
      <div v-for="(item, index) in imgList " :key="index">
        <img class="partner-img-item" :src="item" alt="" />
      </div>

    </div>
  </div>

</template>

<script>
  import {
    defineComponent,
    reactive,
  } from 'vue'

  export default defineComponent({
    components: {},
    setup(props) {
      let imgList = [
        require('@image/partner/partner1.png'),
        require('@image/partner/partner2.png'),
        require('@image/partner/partner3.png'),
        require('@image/partner/partner4.png'),
        require('@image/partner/partner5.png'),
        require('@image/partner/partner6.png'),
        require('@image/partner/partner7.png'),
        require('@image/partner/partner8.png'),
        require('@image/partner/partner9.png'),
        require('@image/partner/partner10.png'),
        require('@image/partner/partner11.png'),
        require('@image/partner/partner12.png'),
        require('@image/partner/partner13.png'),
        require('@image/partner/partner14.png'),
        require('@image/partner/partner15.png'),
        require('@image/partner/partner16.png'),
        require('@image/partner/partner17.png'),
        require('@image/partner/partner18.png'),
        require('@image/partner/partner19.png'),
        require('@image/partner/partner20.png'),
        require('@image/partner/partner21.png'),
        require('@image/partner/partner22.png'),
        require('@image/partner/partner23.png'),
        require('@image/partner/partner24.png'),
        require('@image/partner/partner25.png'),
        require('@image/partner/partner26.png'),
        require('@image/partner/partner27.png'),





        require('@image/partner/partner1.png'),
        require('@image/partner/partner2.png'),
        require('@image/partner/partner3.png'),
        require('@image/partner/partner4.png'),
        require('@image/partner/partner5.png'),
        require('@image/partner/partner6.png'),
        require('@image/partner/partner7.png'),
        require('@image/partner/partner8.png'),
        require('@image/partner/partner9.png'),
        require('@image/partner/partner10.png'),
        require('@image/partner/partner11.png'),
        require('@image/partner/partner12.png'),
        require('@image/partner/partner13.png'),
        require('@image/partner/partner14.png'),
        require('@image/partner/partner15.png'),
        require('@image/partner/partner16.png'),
        require('@image/partner/partner17.png'),
        require('@image/partner/partner18.png'),
        require('@image/partner/partner19.png'),
        require('@image/partner/partner20.png'),
        require('@image/partner/partner21.png'),
        require('@image/partner/partner22.png'),
        require('@image/partner/partner23.png'),
        require('@image/partner/partner24.png'),
        require('@image/partner/partner25.png'),
        require('@image/partner/partner26.png'),
        require('@image/partner/partner27.png')
      ]
      return {
        imgList
      }
    }
  })

</script>

<style scoped>
  .partner-container {
    height: 50px;
    padding-top: 30px;
    background: linear-gradient(180deg, rgba(34,190,190,0.18) 0%, rgba(53,202,202,0.00) 100%);

  }

  .partner-img-item {
    max-height: 50px;
    padding: 0 10px;
  }

  
  .partner-conveyer-belt-container {
    display: flex;
    width: fit-content;
    animation: partner-rolling 28s linear infinite;
  }

  .partner-conveyer-belt-container:hover {
    animation-play-state: paused;
    -webkit-animation-play-state: paused
  }

  @keyframes partner-rolling {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
    }

    to {
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%)
    }
  }

</style>
