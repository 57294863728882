<template>
  <div class="home-banner-container">
    <div class="home-banner-content-container">
      <div class="home-banner-text-slogan-container">
        <div class="home-banner-text-slogan">
          <div class="home-banner-text0">可视化应用</div>
          <div class="home-banner-text1"><span style="color:#23C5D0;font-size: 9rem;">零</span>代码快速搭建平台</div>
          <div class="home-banner-text2">五分钟搭建一个<span style="color:#23C5D0">可视化应用</span></div>
          <a-button class="slogan-button" type="primary" @click="openContactModal">
            联系我们　>
          </a-button>
        </div>
      </div>
      <div class="home-banner-img-container">
        <img :src="require('@image/banner-img-new.png')" class="banner-img-new-pc"/>
        <img :src="require('@image/banner-img-new-phone.png')" class="banner-img-new-phone"/>
      </div>
    </div>
    <!-- <img :src="require('@image/bannerBG.png')" style="width:100%;" /> -->
    <contact-buy :visible="contactModalVisible" title="联系我们" @close="closeContactModal"></contact-buy>
  </div>

</template>

<script setup>
  import { ref } from 'vue';
  import contactBuy from '../price/contactBuy.vue'
  let contactModalVisible = ref(false)
  const openContactModal = () => {
      contactModalVisible.value = true
  }
  const closeContactModal = () => {
      contactModalVisible.value = false
  }
</script>

<style scoped>
  @media screen and (max-width: 800px) {
    .banner-img-new-pc{
      display: none;
    }
    .home-banner-container {
      padding-top: 80px;
      height: auto;
      background: url(~@image/banner-bg-new-phone.png);
    }

    .home-banner-content-container {
      flex-direction: column;
      align-items: center;
    }

    .home-banner-img-container {
      width: 100%;
      margin: auto;
    }
    .home-banner-text0{
      text-align: left;
    }
    .home-banner-text1{
      text-align: right;
    }
    .home-banner-text2{
      text-align: right;
      font-size: 10px;
      font-weight: 400;
      color: #333333;
      line-height: 19px
    }
    .home-banner-content-container{
      position: relative;
    }
    .home-banner-text-slogan-container{
      text-align: center;
      width: 98%;
      padding: 0 15px;
      /* position: relative; */
    }
    .slogan-button{
      position: absolute;
      left: 50%;
      bottom: 16%;
      transform:translate(-50%,-50%)
    }
  }

  /* @media screen and (min-width: 800px) and (max-width: 1300px) {
    .home-banner-text-slogan{
      font-size: 6rem;
    }
  } */

  @media screen and (min-width: 1300px) {
    .home-banner-img-container {
      width: 40%
    }
    .home-banner-text-slogan-container{
      /* width: 50%; */
      padding-left: calc(50% - 648px);
    }
    .home-banner-text-slogan {
      padding-left: 15px;
    }
    .home-banner-text-slogan .home-banner-text2 {
      font-size: 20px;
      margin-top: 20px;
      font-weight: 300;
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      margin-bottom: 25px;
    }
  }
  @media screen and (min-width: 800px) {
    .banner-img-new-phone{
      display: none;
    }
    .home-banner-container {
      margin-top: 80px;
      background: url(~@image/banner-bg-new.png);
    }
  }
  .home-banner-container {
    position: relative;
    background-repeat: round;
    background-size:cover;
  }

  .home-banner-content-container {
    width: 100%;
    height: 100%;
    display: flex;
  }

  /* .home-banner-img-container {
    margin: auto;
  } */
  .home-banner-img-container img {
    width: 100%;
  }

  .home-banner-text-slogan-container {
    /* max-width: 1200px; */
    /* height: 100%; */
    /* margin: 0 auto; */
    display: flex;
    flex-direction: column;
    flex-direction: column;
    /* width: 50%; */
    /* align-items: flex-end; */
    justify-content: center;
  }

  .home-banner-text-slogan {
    /* height: 500px; */
    /* margin-left: -50px; */
    font-weight: 400;
    font-size: 6rem;
    color: #333333;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  .slogan-button{
    color: #FFFFFF;
    border: none;
    background: #23C5D0;
    width: 170px;
    height: 50px;
  }
  .slogan-button:hover{
    background-color: #23C5D0;
    border-color: #23C5D0;
    color: white;
    opacity: 0.9;
  }
  .slogan-button:focus{
    background-color: #23C5D0;
    border-color: #23C5D0;
    color: white;
  }

</style>

<style>

</style>
