<template>
  <div class="footer-banner-container">
    <div class="footer-banner-bg-container">
      <div class="footer-banner-bg-img">
        <div class="footer-banner-slogan-container">
          <div class="footer-banner-slogan">
            <span class="footer-banner-h1">提升空间数据价值</span>
            <!-- <br /> -->
            <span class="footer-banner-h1">展现点线面的科技之美</span>
            <!-- <br /> -->
            <span style="font-size: 14px">畅图云将带给您零代码创建精美可视化场景的体验，为您的企业数字化赋能</span>
          </div>
          <div class="footer-banner-slogan-tool">
            <a-button ghost class="footer-banner-slogan-tool-button" shape="round" @click="toRegister">注册试用</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
  } from 'vue'
  import {linkToRegister} from "@/utils/tool"

  export default defineComponent({
    name: 'footer-banner',
    setup() {
      const toRegister = linkToRegister
      return {
        toRegister
      }
    }
  })

</script>

<style scoped>
  @media screen and (max-width: 800px) {
    .footer-banner-container {
      /* height: 200px; */
    }

    .footer-banner-slogan-container {
      flex-direction: column;
    }

    .footer-banner-slogan {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
    }

    .footer-banner-slogan-tool {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px auto;
    }


  }

  @media screen and (min-width: 800px) {
    .footer-banner-container {
      height: 300px;
    }

    .footer-banner-slogan-tool {
      margin: auto;
    }

    .footer-banner-slogan {
      margin: auto 0;
      flex: 1;
    }
  }

  .footer-banner-container {
    width: 100%;
  }

  .footer-banner-bg-container {
    height: 100%;
    background: #01CACA;
  }

  .footer-banner-bg-img {
    height: 100%;
    background: url('~@image/footerBannerBG111.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 70% 100%;
    position: relative;
  }

  .footer-banner-slogan-container {
    margin: auto;
    padding: 0 15px;
    width: 100%;
    max-width: 1300px;
    height: 100%;
    /* position: absolute; */
    color: #fff;
    font-size: 4.4rem;
    display: flex
  }

  .footer-banner-slogan>span {
    display: block;
  }
  .footer-banner-h1{
    letter-spacing: 0.3rem;
    text-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  }

</style>

<style>
  .footer-banner-container .ant-btn.ant-btn-background-ghost:hover,
  .ant-btn.ant-btn-background-ghost:focus {
    opacity: 0.9;
    background: #fff;
    color: #01CACA;
    border-color: #fff;
  }
  .footer-banner-container .ant-btn.ant-btn-background-ghost{
    background: #fff;
    color: #01CACA;
    border-color: #fff;
    font-weight: 800;
    width: 130px;
    height: 50px;
    font-size: 18px;
  }

</style>
