<template>
    <div class="resource-resourceVideo-container">
        <div class="resourceVideo-menuContainer">
            <!-- <div class="resourceVideo-menuContainer-firstMenu  menu-flex initialMenu-border">
                <div class="firstMenu-item" v-for="item in course" :key="item.id" @click="selectInitialMenu(item)"
                    :class="selectedCourse === item.id ? 'menu-item-selectedMenu1' : ''">
                    {{ item.name }}
                </div>
            </div> -->
            <div class="resourceVideo-menuContainer-firstMenu">
                <a-tabs v-model:activeKey="currentLevel.id" size="large" @tabClick="selectLevel">
                    <a-tab-pane v-for="item in levelList" :key="item.id" :tab="item.label"></a-tab-pane>
                </a-tabs>
            </div>
            <div class="resourceVideo-menuContainer-subMenu menu-flex">
                <div class="menu-title">分类</div>
                <div class="subMenu-item" v-for="item in showClassify" :key="item.id" @click="selectClassify(item)"
                    :class="currentClassify.label === item.label ? 'menu-item-selectedMenu2' : ''">
                    {{ item.label }}
                </div>
            </div>
            <div class="resourceVideo-menuContainer-subMenu menu-flex">
                <div class="menu-title">标签</div>
                <div class="subMenu-item subMenu-item-tag" v-for="item in showTags" :key="item.id" @click="selectTag(item)"
                    :class="currentTag.label === item.label ? 'menu-item-selectedMenu2' : ''">
                    {{ item.label }}
                </div>
            </div>
        </div>
        <div class="resourceVideo-videoContainer">
            <videoCard v-for="item in currentPageShowVideos" :imageUrl="item.coverImage[0].url" :videoName="item.title"
                :videoPath="item.videoPath" :videoIntro="item.briefIntroduction" ref="videoRef"
                @changeShow="changeShowVideo"></videoCard>
        </div>
        <a-pagination v-model:current="currentpagecpt" v-model:page-size="pageSizecpt" :total="showVideosLength"
            :showSizeChanger="true" :show-total="total => `共 ${total} 条`" :responsive="true" :showQuickJumper="true"
            :pageSizeOptions="pageSizeOptions" />
        <!-- <div v-if="showVideo" class="resourceVideo-video">
            <video controls width="500" :src=videoPath></video>
        </div> -->
        <a-modal class="resource-video-modal" v-model:visible="showVideo" @ok="close" :width="850" :footer="null"
            :centered="true">
            <video controls width="800" :src=videoPath></video>
        </a-modal>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, reactive } from 'vue'
import videoCard from '@/views/resource/videoCard'
import {
    getClassify,
    getLevel,
    getTag,
    getVideo,
} from '@/api/resource'

const levelList = ref()
const classifyList = ref()
const tagList = ref()
const videoList = ref()

const currentLevel = reactive({
    label: '基础教程',
    id: 'l1',
})

const currentTag = reactive({
    label: '全部',
    id: 'all',
})
const currentClassify = reactive({
    label: '全部',
    id: 'all',
})

const showVideo = ref(false)
const videoRef = ref()
const videoPath = ref()

const notShowList = ref([])

let initialize = async () => {
    try {
        let _resultLevel = await getLevel()
        let _resultClassify = await getClassify()
        let _resultTag = await getTag()
        let _resultVideo = await getVideo()

        if (_resultLevel.code === 200 && _resultTag.code === 200 && _resultVideo.code === 200 && _resultClassify.code === 200) {
            classifyList.value = _resultClassify.data.filter((item) => {
                if (item.isShow === true) {
                    return true
                } else {
                    notShowList.value.push(item.id)
                }
            })
            classifyList.value.unshift({
                label: '全部',
            })
            levelList.value = _resultLevel.data.filter((item) => {
                if (item.isShow === true) {
                    return true
                } else {
                    notShowList.value.push(item.id)
                }
            })
            sortMenu(levelList.value)

            tagList.value = _resultTag.data.filter((item) => {
                if (item.isShow === true) {
                    return true
                } else {
                    notShowList.value.push(item.label)
                }
            })
            tagList.value.unshift({
                label: '全部',
            })
            videoList.value = _resultVideo.data.filter((item) => {
                if ((item.isShow === true) && isShow(item)) {
                    return true
                }
            })
            currentLevel.id = 'l1'
            currentClassify.label = '全部'
            currentTag.label = '全部'
        }
        selectLevel('l1')
        selectClassify({
            label: '全部'
        })
        selectTag({
            label: '全部'
        })
    } catch (err) {
        console.error(err)
    }
}

onMounted(() => {
    initialize()
    changeShow(1)
})
const props = defineProps({
    searchName: String,
})
const currentpage = ref(1)
const pageSize = ref(6)
const pageSizeOptions = ref(['6', '12', '18'])

const currentpagecpt = computed({
    get() {
        return currentpage.value
    },
    set(val) {
        currentpage.value = val
        changeShow(val)
    }
})
const pageSizecpt = computed({
    get() {
        return pageSize.value
    },
    set(val) {
        currentpage.value = 1
        pageSize.value = val
        changeShow(1)
    }
})
const changeShow = (newPage) => {
    const start = (newPage - 1) * pageSize.value
    currentPageShowVideos.value = showVideos.value.slice(start, start + pageSize.value)
    sortMenu(showClassify.value)
    sortMenu(showTags.value)
}
const sortMenu = (list) => {
    let i
    let j
    let p
    for (i = 0; i < list.length; i++) {
        for (j = i + 1; j < list.length; j++) {
            if (list[i].sort > list[j].sort) {
                p = list[i]
                list[i] = list[j]
                list[j] = p
            }
        }
    }
}
const searchVideo = (videoName) => {
    changeShow(1)
    if (videoName) {
        let arr = showVideos.value.filter((video) => {
            if (video.title.search(videoName) === -1) {
                return false
            } else {
                return true
            }
        });
        const start = (1 - 1) * pageSize.value
        currentPageShowVideos.value = arr.slice(start, start + pageSize.value)
        showVideosLength.value = currentPageShowVideos.value.length
    }
}

const changeShowVideo = (val) => {
    showVideo.value = true
    videoPath.value = val
}
const close = () => {
    showVideo.value = false

}
defineExpose({
    searchVideo
})

const showClassify = ref([])
const showTags = ref([])
const showVideos = ref([])
const currentPageShowVideos = ref([])
const showVideosLength = ref()

const selectLevel = (item) => {
    showClassify.value = [{
        id: 'all',
        label: '全部',
    }]
    if (item === 'l1') {
        currentLevel.label = '基础教程'
        currentLevel.id = 'l1'
    }
    else if (item === 'l2') {
        currentLevel.label = '高级教程'
        currentLevel.id = 'l2'
    }
    else if (item === 'l3') {
        currentLevel.label = '应用教程'
        currentLevel.id = 'l3'
    }
    // showClassify.value = classifyList.value.filter((item) => {
    //     if (true) {
    //         return true
    //     } else {
    //         return false
    //     }
    // })
    videoList.value.filter((itemv) => {
        if (itemv.level === currentLevel.id) {
            classifyIndexOf(itemv.classify)
        }
    })
    showVideos.value = videoList.value.filter((itemv) => {
        if ((itemv.classify === currentClassify.id || currentClassify.id === 'all')
            && (itemv.level === currentLevel.id)
            && (filterTags(itemv.tags, currentTag.id) || currentTag.id === 'all')) {
            // classifyIndexOf(itemv.classify)
            return true
        }
    })
    showVideosLength.value = showVideos.value.length

    selectClassify({
        id: 'all',
        label: '全部'
    })
    searchVideo(props.searchName)
}
const selectClassify = (item) => {
    showTags.value = [{
        id: 'all',
        label: '全部'
    }]
    if (item.label === '全部') {
        // showTags.value = tagList.value
        currentClassify.label = '全部'
        currentClassify.id = 'all'

        showVideos.value = videoList.value.filter((itemv) => {
            if ((itemv.level === currentLevel.id)
                && (filterTags(itemv.tags, currentTag.id) || currentTag.id === 'all')) {
                let i
                for (i = 0; i < itemv.tags.length; i++) {
                    tagIndexOf(itemv.tags[i])
                }
                return true
            }
        })
    } else {
        // showTags.value = tagList.value.filter((tag) => {
        //     return true
        // })
        currentClassify.label = item.label
        currentClassify.id = item.id
        videoList.value.filter((itemv) => {
            if ((itemv.classify === currentClassify.id || currentClassify.id === 'all')
                && (itemv.level === currentLevel.id)) {
                let i
                for (i = 0; i < itemv.tags.length; i++) {
                    tagIndexOf(itemv.tags[i])
                }
            }
        })
        showVideos.value = videoList.value.filter((itemv) => {
            if ((itemv.classify === currentClassify.id || currentClassify.id === 'all')
                && (itemv.level === currentLevel.id)
                && (filterTags(itemv.tags, currentTag.id) || currentTag.id === 'all')) {
                return true
            }
        })
    }
    showVideosLength.value = showVideos.value.length
    selectTag({
        id: 'all',
        label: '全部'
    })
    searchVideo(props.searchName)
}
const selectTag = (item) => {
    if (item.label === '全部') {
        showVideos.value = videoList.value.filter((itemv) => {
            if ((itemv.classify === currentClassify.id || currentClassify.id === 'all')
                && (itemv.level === currentLevel.id)) {
                return true
            }
        })
        currentTag.label = '全部'
        currentTag.id = 'all'
        searchVideo(props.searchName)
    } else {
        currentTag.label = item.label
        currentTag.id = item.id
        showVideos.value = videoList.value.filter((itemv) => {
            if ((filterTags(itemv.tags, currentTag.id) || currentTag.id === 'all')
                && (itemv.classify === currentClassify.id || currentClassify.id === 'all')
                && (itemv.level === currentLevel.id)) {
                return true
            }
        })
        searchVideo(props.searchName)
    }
    showVideosLength.value = showVideos.value.length
}
const tagIndexOf = (tag) => {
    let i
    let count = 0
    for (i = 0; i < showTags.value.length; i++) {
        if (showTags.value[i].id === tag) {
            count++
        }
    }
    if (count === 0) {
        const tagObject = {
            id: tag,
            label: getTagLabel(tag),
            sort: parseInt(tag)
        }
        showTags.value.push(tagObject)
    }
}
const classifyIndexOf = (classify) => {
    let i
    let count = 0
    for (i = 0; i < showClassify.value.length; i++) {
        if (showClassify.value[i].id === classify) {
            count++
        }
    }
    if (count === 0) {
        showClassify.value.push({
            id: classify,
            label: getClassifyLabel(classify),
            sort: getClassifySort(classify)
        })
    }
}
const getClassifyLabel = (classifyId) => {
    switch (classifyId) {
        case '2d': return '二维';
        case 'bim': return 'BIM';
        case 'earth': return 'Earth';
        case '3d': return '三维';
    }
}
const getClassifySort = (classifyId) => {
    switch (classifyId) {
        case '2d': return 1;
        case 'bim': return 3;
        case 'earth': return 2;
        case '3d': return 0;
    }
}
const getTagLabel = (tagId) => {
    switch (tagId) {
        case 1: return '智慧矿山';
        case '1': return '智慧矿山';
        case 2: return '智慧园区';
        case '2': return '智慧园区';
        case 3: return '智慧水利';
        case '3': return '智慧水利';
        case 4: return '智慧社区';
        case '4': return '智慧社区';
        case 5: return '轨道交通';
        case '5': return '轨道交通';
        case 6: return '智慧工地';
        case '6': return '智慧工地';
        case 7: return '其他';
        case '7': return '其他';
    }
}

const isShow = (item) => {
    let i = 0
    let j = 0
    let count = 0
    for (i = 0; i < notShowList.value.length; i++) {
        if (item.level === notShowList.value[i]) {
            count++
        }
        if (item.classify === notShowList.value[i]) {
            count++
        }
        for (j = 0; j < item.tags.length; j++) {
            if (getTagLabel(item.tags[j]) === notShowList.value[i]) {
                count++
            }
        }
    }
    if (count === 0) {
        return true
    } else {
        return false
    }
}
const filterTags = (tagArray, currentTag) => {
    let i
    for (i = 0; i < tagArray.length; i++) {
        if (tagArray[i] == currentTag) {
            return true
        }
    }
    return false
}
</script>

<style scoped>
.resource-resourceVideo-container {
    width: 100%;
    /* height: 50rem; */
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(35, 197, 208, 0.05);
}

.resourceVideo-menuContainer {
    display: flex;
    flex-direction: column;
    height: 10rem;
    /* width: 60%; */
    width: 1500px;
    margin-left: 200px;
    margin-bottom: 2rem;
}

.resourceVideo-menuContainer-firstMenu {
    width: 100%;
    height: 50%;
    margin-bottom: 40px;
}

.resourceVideo-menuContainer-subMenu {
    width: 100%;
    height: 30%;
    margin-bottom: 30px;
}

.menu-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.menu-title {
    color: #000000;
    opacity: 0.5;
    font-size: 18px;
    line-height: 5rem;
    margin-right: 4rem;
}

.firstMenu-item {
    color: gray;
    font-size: 2rem;
    margin-right: 5rem;
    cursor: pointer;
    margin-bottom: -0.2rem;
    padding: 1rem;
}

.subMenu-item {
    color: gray;
    font-size: 20px;
    margin-right: 4rem;
    cursor: pointer;
    margin-bottom: -0.2rem;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 3rem;
    border-radius: 0.5rem;
    text-align: center;
}

.subMenu-item-tag {
    margin-bottom: 10px;
    margin-top: 10px;
}

.subMenu-item:hover {
    color: #23C5D0;
}

.menu-item-selectedMenu1 {
    color: #23C5D0;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
}

.menu-item-selectedMenu2 {
    color: #23C5D0;
    background-color: #23c5d02b;
}

.resourceVideo-videoContainer {
    /* width: 64%; */
    width: 1500px;
    margin-top: 80px;
    margin-left: 200px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 5rem;
    padding-bottom: 7rem;
}

.resourceVideo-video {
    position: absolute;
}

.initialMenu-border {
    border-bottom: 0.1rem solid rgba(152, 157, 167, 0.2);
    margin-bottom: 3rem;
}

@media screen and (max-width: 800px) {
    .resourceVideo-menuContainer {
        width: 100%;
        margin-left: 40px;
        height: 180px;
    }

    .resourceVideo-menuContainer-firstMenu {
        margin-bottom: 30px;
        height: 30%;
    }

    .resourceVideo-menuContainer-subMenu {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        height: 20%;
        margin-bottom: 20px;
    }

    .menu-flex {
        flex-wrap: nowrap;
    }

    .menu-title {
        font-size: 18px;
    }

    .firstMenu-item {
        font-size: 18px;
    }

    .subMenu-item {
        font-size: 18px;
        padding: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .resourceVideo-videoContainer {
        justify-content: space-between;
        width: 100%;
        margin-top: 0;
        margin-left: 0;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
    .subMenu-item {}

    .resourceVideo-menuContainer {
        width: 120rem;
        margin-left: 0;
    }

    .resourceVideo-videoContainer {
        width: 140rem;
        margin-left: 10rem;
    }

    .resourceVideo-menuContainer-subMenu {
        margin-bottom: 5rem;

    }

    .resourceVideo-videoContainer {
        margin-top: 150px;
    }

}

/* 下划线修改 */
:deep(.ant-tabs-ink-bar) {
    background-color: #23C5D0;
}

/* 选中颜色修改 */
:deep(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: #23C5D0;
}

/* 悬浮颜色修改 */
:deep(.ant-tabs-tab:hover) {
    color: #23C5D0;
}

/* 字体大小修改 */
:deep(.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab) {
    font-size: 20px;
}

:deep(.ant-modal-content) {
    background-color: transparent !important;
}

:deep(.ant-modal-close) {
    top: 20px;
    right: 20px;
}


:deep(.ant-pagination-item-active) {
    border-color: #23C5D0;
    background-color: #23C5D0;
}

:deep(.ant-pagination-item-active a) {
    color: #FFFFFF;
}

:deep(.ant-pagination-item-active:hover a) {
    color: #FFFFFF;
}

:deep(.ant-pagination-item-active:hover) {
    border-color: transparent;
}

:deep(.ant-select:not(.ant-select-disabled):hover .ant-select-selector) {
    border-color: #23C5D0;
}
</style>

<style>
.resource-video-modal .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
}

.resource-video-modal .ant-modal-close {
    color: #FFFFFF;
    top: 23px;
    right: 26px;
}

.resource-video-modal .ant-modal-close:hover {
    color: #23C5D0;
}
</style>
