<template>
  <div class="footer-container">
    <div class="footer-nav">
      <img class="footer-nav-logo" :src="require('@image/footer-logo.png')" alt="" @click="goHome">
      <div class="footer-nav-right-container">
        <div class="footer-nav-item" v-for="item in state.menu" :key="item.id" @click="linkTo(item)">{{item.name}}</div>
      </div>
    </div>
    <a-divider style="background: rgba(255,255,255,0.4);" />
    <div class="footer-contact-container">
      <div class="footer-contact-text-container">
        <a-typography-text class="footer-contact-text-item footer-contact-text1"><img
            :src="require('@image/footer-site.png')" style="margin-right: 1rem;" class="text-img">
          <div class="text-key">北京地址：</div>
          <div class="footer-contact-text-address1">北京市海淀区清华东路35号北京林业大学学研中心C栋B103</div>
        </a-typography-text>
        <a-typography-text class="footer-contact-text-item" style="margin-left: 3rem;">
          <div class="text2-key">郑州地址：</div>
          <div>河南省郑州市金水区龙湖中环北路启迪科技城15号楼</div>
        </a-typography-text>
        <a-typography-text class="footer-contact-text-item"><img :src="require('@image/footer-phone.png')"
            style="margin-right: 0.5rem;" class="text-img">电话：010-62326117　　　　　　<img
            :src="require('@image/footer-QQ.png')" style="margin-right: 0.5rem;" class="text-img">QQ技术交流：813165113
        </a-typography-text>
        <!-- <a-typography-text class="footer-contact-text-item"></a-typography-text> -->
      </div>
      <div class="footer-contact-other-container">
        <div class="footer-contact-other-qccode">
          <div class="footer-contact-other-qccode-wrap">
            <img :src="require('@image/QRCode/wxgongzhonghao.png')" alt="微信公众号" />
          </div>
          <span class="footer-contact-qccode-title">畅图云公众号</span>
        </div>

        <div class="footer-contact-other-qccode">
          <div class="footer-contact-other-qccode-wrap">
            <img :src="require('@image/QRCode/wxxiaochengxu.png')" alt="微信小程序" />
          </div>
          <span class="footer-contact-qccode-title">畅图云小程序</span>
        </div>
        <div class="footer-contact-other-qccode">
          <div class="footer-contact-other-qccode-wrap">
            <img :src="require('@image/QRCode/qqqun.png')" alt="QQ群" />
          </div>
          <span class="footer-contact-qccode-title">畅图云QQ群</span>
        </div>
        <div class="footer-contact-other-qccode">
          <div class="footer-contact-other-qccode-wrap">
            <img :src="require('@image/QRCode/bzhan.png')" alt="B站" />
          </div>
          <span class="footer-contact-qccode-title">畅图云B站</span>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
  } from 'vue'
  import {
    useRouter
  } from 'vue-router'
  import {
    NAV_CONFIG
  } from "@/utils/PAGE-CONFIG.js"

  export default defineComponent({
    setup() {
      const router = useRouter()
      const state = reactive({
        menu: NAV_CONFIG
      })
      const goHome = () => {
        router.push('/')
      }
      const linkTo = (data) => {
        if (data.path && data.path !== '') {
          router.push(data.path)
        } else {
          data.link && window.open(data.link)
        }
      }
      return {
        state,
        goHome,
        linkTo
      }
    }
  })

</script>

<style scoped>
  @media screen and (max-width: 800px) {
    .footer-contact-container {
      flex-direction: column;
      align-items: center;
    }

    .footer-nav-logo {
      /* margin-left: 15px; */
      width: 20rem;
    }

    .footer-contact-other-container {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
    }

    .footer-contact-other-qccode {
      /* margin: 10px; */
    }

    .footer-contact-other-qccode-wrap {
      width: 33rem;
      height: 33rem;
    }

    .footer-contact-other-qccode-wrap img {
      width: 33rem;
      height: 33rem;
    }

    .footer-contact-text-item {
      font-size: 10px;
    }

    .footer-contact-text1 {
      font-size: 14px;
      font-weight: 900;
    }

    .text2-key {
      margin-left: 1.5rem;
      width: 13rem;
    }

    .text-key {
      width: 16rem;
    }

    .text-img {
      width: 4rem;
      height: 4rem;
    }
  }


  @media screen and (min-width: 800px) {
    .footer-contact-other-container {
      align-items: center;
      justify-content: flex-end;
      align-content: center;
    }

    .footer-contact-other-qccode {
      margin-left: 3rem;
    }

    .footer-contact-other-container img {
      height: 120px;
    }

    .footer-contact-other-qccode-wrap {
      border-radius: 8px;
    }
    .footer-contact-text1, .footer-contact-text-address1{
      font-size: 20px;
      font-weight: 900;
    }
  }

  .footer-container {
    margin: 36px auto;
    max-width: 1300px;
    padding: 0 15px;
  }

  .footer-nav {
    height: 100%;
    padding-top: 5rem;
    display: flex;
  }

  .footer-nav-logo {
    border-radius: 3px;
    cursor: pointer;
    margin-right: 3rem;
  }

  .footer-nav-right-container {
    margin-left: 30px;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    font-size: 14px;
    height: 100%;
    margin: auto 0;
    /* color: #08979C; */
    width: 300px;
    justify-content: space-around;
  }

  .footer-nav-item {
    /* margin: auto 30px; */
    cursor: pointer;
    position: relative;
    color: #FFFFFF;
  }

  .footer-contact-container {
    display: flex;
  }

  .footer-contact-text-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .footer-contact-text-item {
    display: flex;
    margin: 10px 0;
    color: #FFFFFF;
  }

  .footer-contact-other-container {
    flex: 1;
    display: flex;
    width: 100%;
  }


  .footer-contact-other-qccode-wrap {
    border: solid 1px #ddd;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .footer-contact-other-qccode {
    text-align: center;
  }

  .footer-contact-qccode-title {
    /* color: rgba(0, 0, 0, 0.65) */
    color: #FFFFFF;
  }

</style>

<style>
  .footer-container .ant-divider-horizontal {
    margin: 12px 0;
  }

</style>
