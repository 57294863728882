<template>
  <a-config-provider :locale="locale">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </a-config-provider>
</template>

<script>
  import {
    defineComponent,
    onMounted
  } from 'vue'
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  import "@style/css/index.css"


  dayjs.locale('zh-cn');
  export default defineComponent({
    name: 'App',
    setup() {
      let locale = zhCN

      const _initRem = () => {
        // 获取屏幕宽度
        var width = document.documentElement.clientWidth
        // 设置根元素字体大小。此时为宽的10等分
        if(width < 700){
          document.documentElement.style.fontSize = '5.5px'
        }else if(width < 1300){
          document.documentElement.style.fontSize = width / 130 + 'px'
        } else {
          document.documentElement.style.fontSize = '10px'
        }
        
      }
      onMounted(() => {
        _initRem()
        // 监听手机旋转的事件的时机，重新设置
        window.addEventListener('orientationchange', _initRem)
        // 监听手机窗口变化，重新设置
        window.addEventListener('resize', _initRem)
      })
      return {
        locale
      }
    }
  })

</script>
<style>
  #app {
    height: 100%;
    width: 100%
  }

  .root {
    height: 100%;
    width: 100%
  }

</style>
