<template>
  <div class="page-container">
    <Header></Header>
    <FixedBox></FixedBox>
    <div class="page-content" id="pageContent">
      <div class="page-conent-container">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
        <div class="page-footer">
          <Footer></Footer>
          <a-divider />
          <filing-information></filing-information>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    ref,
  } from 'vue'
  import Header from './header/index.vue'
  import FixedBox from './fixedBox/index.vue'
  import Footer from './footer/index.vue'
  import filingInformation from './filing/index.vue'
  export default defineComponent({
    name: 'App',
    components: {
      Header,
      FixedBox,
      Footer,
      filingInformation,
    },
    setup() {
    }
  })

</script>
<style scoped>
  .page-container {
    width: 100%;
    height: 100%;
    /* display: flex;
    flex-direction: column; */
  }

  .page-container .page-header {
    height: 100px;
    background: rgba(255, 0, 0, 0.3);
  }

  .page-container .page-content {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .page-conent-container {
    height: 100%;
  }

  .page-container .page-content .page-footer {
    /* height: 500px; */
    /* background: rgba(0, 0, 255, 0.3); */
    background: #23C5D0;
  }
</style>

<style>
  .page-footer>.ant-divider-horizontal{
    margin: 0;
  }
</style>
