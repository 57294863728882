<template>
  <div class="price-footerBanner-container"
    :style="{background: `url(${require('@image/price/footerBanner/bg.png')})`, 'background-size': 'cover'}">
    <div class="price-footerBanner-container-bg"
      :style="{background: `url(${require('@image/price/footerBanner/bg2.png')})`, 'background-size': 'cover'}">
      <div class="price-footerBanner-text">
        <div class="price-footerBanner-text1">快把想法变成应用</div>
        <div class="price-footerBanner-text2">将畅图云部署到您的私有服务器上，让您拥有完整的零代码企业应用搭建和运行能力。</div>
        <div class="price-footerBanner-text2">查看介绍</div>
      </div>
      <div class="price-footerBanner-bts">
        <a-button class="price-footerBanner-bt" @click="toLogin">立即体验</a-button>
        <a-button class="price-footerBanner-bt" @click="toPageTop">选购套餐</a-button>
      </div>
      <div class="price-footerBanner-text3">有疑问? 欢迎致电010-62326117</div>
    </div>
  </div>
</template>

<script>
  import {
    defineComponent
  } from 'vue'
  import {toTop} from "@/utils/tool"
  import {linkToLogin} from "@/utils/tool"

  export default defineComponent({
    name: 'price',
    components: {},
    setup() {
      const toPageTop = toTop
      const toLogin = linkToLogin
      return {
        toPageTop,
        toLogin
      }
    }
  })

</script>
<style lang="less" scoped>
  .price-footerBanner-container {
    width: 100%;
    height: 400px;

    .price-footerBanner-container-bg {
      width: 100%;
      height: 400px;
      // opacity: 0.6;


      .price-footerBanner-text {
        padding-top: 60px;

        .price-footerBanner-text1 {
          width: 531px;
          height: 44px;
          opacity: 1;
          margin: auto;
          font-family: 'SourceHanSansCN-Bold';
          font-size: 40px;
          font-weight: bold;
          line-height: 44px;
          text-align: center;
          color: #FFFFFF;
          text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        }

        .price-footerBanner-text2 {
          margin: auto;
          margin-top: 30px;
          width: 700px;
          font-family: 'SourceHanSansCN-Medium';
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF;
          text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
          opacity: 1;
        }
      }

      .price-footerBanner-bts {
        text-align: center;
        margin-top: 45px;

        .price-footerBanner-bt {
          width: 175px;
          height: 64px;
          margin-left: 19px;
          border-radius: 32px;
          opacity: 1;
          background: #FFFFFF, linear-gradient(180deg, #FFFFFF 0%, #CCFFFF 100%);
          box-shadow: 0px 4px 10px 0px rgba(0, 131, 131, 0.2), inset 0px 4px 10px 0px rgba(255, 255, 255, 0.3);
          font-family: 'SourceHanSansCN-Bold';
          font-size: 20px;
          font-weight: bold;
          color: #00AA9F;
          letter-spacing: 0.86px;
        }
      }

      .price-footerBanner-text3 {
        text-align: center;
        margin-top: 30px;
        font-family: 'SourceHanSansCN-Medium';
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }

  @media screen and (max-width: 800px) {
    .price-footerBanner-container {
      .price-footerBanner-container-bg {
        .price-footerBanner-text {
          .price-footerBanner-text1 {
            width: 100%;
            font-size: 30px;
          }

          .price-footerBanner-text2 {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
          }
        }

        .price-footerBanner-bts {
          .price-footerBanner-bt {
            width: 140px;
            height: 50px;
            font-size: 16px;
          }
        }
      }
    }
  }

</style>
