import {
  get,
  post
} from "@/axios/http.js";
import qs from 'qs'

const EXAMPLE_APP_CONFIG = {
  "App-Key": "61af041a1f3214a20ff35264",
  "Sign": "PrUM8Hf5KYc4QnLEDtPH1edGNwF8Mew3P.tJc5I3LEmws2SQF.vSjmuPfONB0N1Fh-jZglqYdC0YvnAkz0GWf2yNKRA8huJkpR5tazQXN88u.tZ5t1TIimBQS0Fkjm3u",
}
export const getClassify = () => {
  let params = {
    "App-Key": EXAMPLE_APP_CONFIG['App-Key'],
    "Sign": EXAMPLE_APP_CONFIG['Sign'],
    "worksheetId": "6377041b132822ad13f7534b",
    "pageSize": 100000,
    "pageNo": 1,
    "sortField": "",
    "isAsc": false,
    "keywords": "",
    "filters": []
  }
  return post('./api/v2/worksheet/getFilterRows', params)
}

export const getExampleList = () => {
  let params = {
    "App-Key": EXAMPLE_APP_CONFIG['App-Key'],
    "Sign": EXAMPLE_APP_CONFIG['Sign'],
    "worksheetId": "63760405132822ad13f708f3",
    "pageSize": 100000,
    "pageNo": 1,
    "sortField": "",
    "isAsc": false,
    "keywords": "",
    "filters": []
  }
  return post('./api/v2/worksheet/getFilterRows', params)
}

export const getTags = () => {
  let params = {
    "App-Key": EXAMPLE_APP_CONFIG['App-Key'],
    "Sign": EXAMPLE_APP_CONFIG['Sign'],
    "worksheetId": "637c67cbaeb30f624df48d4d",
    "pageSize": 100000,
    "pageNo": 1,
    "sortField": "",
    "isAsc": false,
    "keywords": "",
    "filters": []
  }
  return post('./api/v2/worksheet/getFilterRows', params)
}

export const addConsultation = (data) => {
  let params = {
    "App-Key": EXAMPLE_APP_CONFIG['App-Key'],
    "Sign": EXAMPLE_APP_CONFIG['Sign'],
    "worksheetId": "65f15f3df94215fca7ba68eb",
    "data":data
  }
  return post('./api/v2/worksheet/addRow', params)
}

