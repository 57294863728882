<template>
  <div class="portal-layout-container">
    <div class="portal-layout-header-container">
      <div class="portal-layout-header-wrap">
        <div class="portal-layout-header-title">
          <span>使用场景示例</span>
        </div>
        <div class="portal-layout-header-tabs-container">
          <div class="portal-layout-header">
            <a-tabs v-model:activeKey="showCategory" centered :tabBarGutter="tabBarGutter" @change="changeCategory"
              :class="categoryList.length === 0 ? 'portal-layout-tabs-hidden' : ''">
              <a-tab-pane v-for="item in categoryList" :key="item.id">
                <template #tab>
                  <span class="portal-layout-tabs-text" :style="item.color?'color:#08979C':''">
                    <!-- <img class="portal-layout-tabs-img" :src="item.icon">
                    <br> -->
                    {{item.title}}
                  </span>
                </template>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div class="portal-layout-tags-container">
            <div class="protal-layout-tag" :class="tagState.activeTags.includes(tag.id)?'protal-layout-tag-active':''"
              v-for="tag in tagState.showTags" :key="tag.id" @click="clickTag(tag)">{{tag.label}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="portal-layout-content">
      <div class="portal-layout-content-wrap">
        <div class="cgs-card-container" v-if="visualType === 'card'">
          <cgs-card :scene="scene" v-for="scene in senceList" :key="scene.id" :default-img="defaultImg" :menu="menu"
            @enterVideo="enterVideo" @enterDEMO="enterDEMO">
            <!-- <template #bottom="{data}">
            </template> -->
          </cgs-card>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
  import {
    ref,
    defineComponent,
    watch,
    reactive,
    onMounted
  } from 'vue'
  import cgsCard from './cgsCard.vue'

  function listSource(props) {
    let list = ref(props.senceList)
    watch(() => props.senceList, (newValue) => {
      list.value = newValue
    })
    return {
      list
    }
  }

  function categoryListSource(props) {
    let categoryList = ref(props.categoryList)
    let showCategory = ref(props.showCategory)
    watch(() => props.categoryList, (newValue) => {
      categoryList.value = newValue
    })
    watch(() => props.showCategory, (newValue) => {
      showCategory.value = newValue
    })
    return {
      categoryList,
      showCategory
    }
  }

  function tagsSource(props) {
    const tagState = reactive({
      showTags: [],
      activeTags: []
    })
    const setTagState = () => {
      let _usedTagList = new Set()
      let _senceList = props.allSenceList
      _senceList.forEach(_item => {
        let _tags = _item.tags;
        if (_tags && _tags.length > 0) {
          _tags.forEach(_tag => {
            _usedTagList.add(_tag.toString())
          })
        }
      })
      if (props.tags && props.tags.length > 0) {
        tagState.showTags = props.tags.filter(_item => _usedTagList.has(_item.id))
      }
    }
    watch(() => props.allSenceList, (newValue) => {
      setTagState()
    })
    watch(() => props.tags, (newValue) => {
      setTagState()
    })
    watch(() => props.activeTags, (newValue) => {
      tagState.activeTags = newValue
    })
    return tagState
  }

  export default defineComponent({
    components: {
      cgsCard
    },
    props: {
      senceList: {
        type: Array,
        default: []
      },
      allSenceList: {
        type: Array,
        default: []
      },
      categoryList: {
        type: Array,
        default: []
      },
      showCategory: {
        type: String,
        default: ''
      },
      menu: {
        type: Array,
        default: []
      },
      tags: {
        type: Array,
        default: []
      },
      activeTags: {
        type: Array,
        default: []
      },
      defaultImg: {
        type: String,
        default: ''
      }
    },
    setup(props, ctx) {
      let {
        categoryList,
        showCategory
      } = categoryListSource(props)
      let {
        list
      } = listSource(props)
      let tagState = tagsSource(props)
      let visualType = ref('card')
      let tabBarGutter = ref(100)
      let addScene = () => {
        ctx.emit('addScene')
      }
      let changeCategory = (key) => {
        ctx.emit('changeCategory', key)
        ctx.emit('update:showCategory', key)
      }
      let clickCard = (obj) => {
        enterDEMO(obj)
      }
      let clickMenu = (scene, menuItem) => {
        ctx.emit('clickMenu', scene, menuItem)
      }
      let enterDEMO = (data) => {
        ctx.emit('enterDEMO', data)
      }
      let enterVideo = (data) => {
        ctx.emit('enterVideo', data)
      }
      let clickTag = (data) => {
        let _activeTags = JSON.parse(JSON.stringify(tagState.activeTags))
        let _index = _activeTags.findIndex(_item => _item === data.id)
        if (_index > -1) {
          _activeTags.splice(_index, 1)
        } else {
          _activeTags.push(data.id)
        }
        ctx.emit('update:activeTags', _activeTags)
        ctx.emit('changedActiveTags', _activeTags)
      }

      const _initRem = () => {
        var width = document.documentElement.clientWidth
        if (width < 1300) {
          tabBarGutter.value = width / 20
        } else {
          tabBarGutter.value = 100
        }
      }
      onMounted(() => {
        _initRem()
        window.addEventListener('orientationchange', _initRem)
        window.addEventListener('resize', _initRem)
      })
      return {
        visualType,
        categoryList,
        showCategory,
        list,
        tagState,
        tabBarGutter,
        addScene,
        changeCategory,
        clickCard,
        clickMenu,
        clickTag,
        enterVideo,
        enterDEMO
      }
    }
  })

</script>

<style scoped>
  @media screen and (min-width: 800px) {
    .portal-layout-header-tabs-container {
      /* height: 230px */
    }

    .portal-layout-tags-container {
      justify-content: center;
      flex-wrap: wrap;
    }

    .portal-layout-tabs-img {
      display: inline;
    }

    .portal-layout-content {
      /* transform: translateY(-260px); */
    }

  }

  @media screen and (max-width: 800px) {
    .portal-layout-header-tabs-container {
      /* height: 180px */
    }

    .portal-layout-tags-container {
      justify-content: space-between;
    }

    .portal-layout-tabs-img {
      display: none;
    }

    .portal-layout-content {
      /* transform: translateY(-260px); */
    }
  }

  .cgs-card-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .cgs-list-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 10px;
  }

  .portal-layout-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .portal-layout-header-title {
    font-size: 5.4rem;
    font-weight: 900;
    margin: 90px auto;
    text-align: center;
    line-height: 84px;
    color: #fff;
    letter-spacing: 0.5rem;
    text-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  }

  .portal-layout-header-container {
    width: 100%;
    /* height: 703px; */
    padding: 0 10px;

  }

  .portal-layout-header-wrap {
    max-width: 1300px;
    margin: 0 auto;
  }

  .portal-layout-header-tabs-container {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(241, 255, 253, 0.85) 100%);
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(23, 164, 155, 0.1), inset 0px 4px 10px 0px #FEFFFF;
    border-radius: 10px;
  }

  .portal-layout-header {
    padding-top: 10px;
  }

  .portal-layout-header-tool {
    padding-left: 20px;
    padding-top: 5px;
    float: right;
  }

  .portal-layout-content {
    flex: 1;
    height: 0;
    overflow-y: auto;
    background-image: url(~@image/example/circle.png);
    background-repeat: round;
    padding: 40px 10px;    
    /* margin: 40px 0px; */
  }

  .portal-layout-tags-container {
    margin: auto;
    display: flex;
    overflow-y: auto
  }

  .protal-layout-tag {
    background-color: #fff;
    border-radius: 24.5px;
    margin-right: 10px;
    color: #00AA9F;
    font-size: 14px;
    text-align: center;
    line-height: 29px;
    cursor: pointer;
    margin: 0 23px 23px;
    transition: color .3s;
    transition: box-shadow .3s;
    width: 13rem;
    min-width: 13rem;
    height: 29px;
    border: 1px solid #00AA9F;
  }

  .protal-layout-tag:not(.protal-layout-tag-active):hover {
    /* border-width: 0px; */
    /* color: rgb(124, 145, 155); */
    box-shadow: rgb(0 170 159 / 20%) 0px 6px 12px;
  }

  .protal-layout-tag-active {
    border-width: 0px;
    color: #fff;
    background: #00AA9F;
    box-shadow: rgb(0 170 159 / 20%) 0px 6px 12px;
  }

  .portal-layout-content-wrap {
    max-width: 1300px;
    margin: 0 auto;
  }

  .portal-layout-tabs-text {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0px;
  }

  .portal-layout-tabs-img {
    width: 100px;
    height: 100px;
    margin-bottom: 5px
  }

</style>

<style>
  .portal-layout-tabs-hidden.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
    display: none;
  }

  .portal-layout-tabs-hidden.ant-tabs>.ant-tabs-nav .ant-tabs-extra-content {
    flex: 1;
  }

  .portal-layout-header .ant-tabs-tab {
    text-align: center;
  }
  .portal-layout-header .ant-tabs-tab:hover{
    color: #08979C;
  }

  .portal-layout-header .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #08979C;
  }

  .portal-layout-header .ant-tabs-ink-bar {
    background: #08979C;
  }

</style>
