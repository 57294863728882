<template>
  <div class="footer-banner-container">
    <div class="footer-banner-bg-container">
      <div class="footer-banner-bg-img">
        <div class="footer-banner-slogan-container">
          <div class="footer-banner-slogan">
            <span class="footer-banner-h1"><span style="color:#23C5D0">“</span>提升空间数据价值<span style="color:#23C5D0">”</span></span>
            <!-- <br /> -->
            <span class="footer-banner-h1">展现<span style="color:#23C5D0">点线面</span>的科技之美</span>
            <!-- <br /> -->
            <span class="footer-banner-text3">畅图云将带给您零代码创建精美可视化场景的体验，为您的企业数字化赋能</span>
          </div>
          <div class="footer-banner-slogan-tool">
            <a-button class="footer-banner-slogan-tool-button" @click="toRegister">注册试用　></a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
  } from 'vue'
  import {linkToRegister} from "@/utils/tool"

  export default defineComponent({
    name: 'footer-banner',
    setup() {
      const toRegister = linkToRegister
      return {
        toRegister
      }
    }
  })

</script>

<style scoped>
  @media screen and (max-width: 800px) {
    .footer-banner-container {
      /* height: 200px; */
    }
    .footer-banner-text3{
      font-size: 8px
    }
    .footer-banner-bg-img {
      background: url('~@image/footerBanner-bg-phone.png');
    }
  }
  .footer-banner-slogan-container {
    flex-direction: column;
  }

  .footer-banner-slogan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  }

  .footer-banner-slogan-tool {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
  }
  .footer-banner-slogan-tool-button{
    color: #FFFFFF;
    border: none;
    background: #23C5D0;
    width: 170px;
    height: 50px;
  }
  .footer-banner-slogan-tool-button:hover{
    color: #FFFFFF;
    background-color: #23C5D0;
    border-color: #23C5D0;
    opacity: 0.9;
  }

  @media screen and (min-width: 800px) {
    .footer-banner-container {
      height: 360px;
    }
    .footer-banner-text3{
      font-size: 14px
    }
    .footer-banner-bg-img {
      background: url('~@image/footerBanner-bg.png');
    }
  }

  .footer-banner-container {
    width: 100%;
  }

  .footer-banner-bg-container {
    height: 100%;
    background: #01caca;
    
  }

  .footer-banner-bg-img {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 70% 100%;
    position: relative;
    padding: 16px 0px;
  }

  .footer-banner-slogan-container {
    margin: auto;
    padding: 0 15px;
    width: 100%;
    max-width: 1300px;
    height: 100%;
    /* position: absolute; */
    /* color: #fff; */
    font-size: 4.4rem;
    display: flex;
    justify-content: center;
  }

  .footer-banner-slogan>span {
    display: block;
  }
  .footer-banner-h1{
    letter-spacing: 0.3rem;
    text-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  }
  span{
    white-space:nowrap;
  }

</style>

<style>
  .footer-banner-container .ant-btn.ant-btn-background-ghost:hover,
  .ant-btn.ant-btn-background-ghost:focus {
    opacity: 0.9;
    background: #fff;
    color: #01CACA;
    border-color: #fff;
  }
  .footer-banner-container .ant-btn.ant-btn-background-ghost{
    background: #fff;
    color: #01CACA;
    border-color: #fff;
    font-weight: 800;
    width: 130px;
    height: 50px;
    font-size: 18px;
  }

</style>
