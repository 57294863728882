<template>
  <div class="filing-information-container">
    <a-typography-link href="https://beian.miit.gov.cn/" target="_blank">京ICP备18004264号-3 版权所有 ©  北京畅图科技有限公司</a-typography-link>
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
  } from 'vue'

  export default defineComponent({
    name: 'filing-information',
    setup() {
    }
  })

</script>

<style scoped>
.filing-information-container{
    text-align: center;
    color:#767e89;
    font-size: 12px;
}

</style>
<style>
.filing-information-container a.ant-typography, .ant-typography a{
  color:#767e89;
}
</style>
