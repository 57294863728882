import axios from 'axios'

// 创建 axios 实例
export const requests = axios.create({
  baseURL: '',
  timeout: 60000, // 请求超时时间
  // withCredentials: true
})
// 错误处理函数
const err = (error) => {
  return Promise.reject(error)
}

const requestInterceptor = config => {
  return config
}

const responseInterceptor = (response) => {
  if (response.status === 200) {
    const res = response.data
    return res
  } else {
    return Promise.reject('error')
  }

}

// request interceptor(请求拦截器)
requests.interceptors.request.use(requestInterceptor, err)

// response interceptor（接收拦截器）
requests.interceptors.response.use(responseInterceptor, err)
