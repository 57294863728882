<template>
  <div class="price-useDetail-container">
    <div class="price-useDetail-table">
      <div class="price-useDetail-table-top">
        <span><img src="@image/price/useDetail/check_true.png" style="margin-right:8px">完整包含</span>
        <span><img src="@image/price/useDetail/check.png" style="margin-right:8px">部分包含</span>
        <span><img src="@image/price/useDetail/check_false.png" style="margin-right:8px">不包含</span>
      </div>
      <div style="clear:both"></div>
      <div class="price-use-detail-header-container">
        <div class="price-use-detail-header-title">产品特性</div>
        <div class="price-use-detail-header-table">
          <div>试用版</div>
          <div>标准版</div>
          <div>专业版</div>
        </div>

      </div>
      <a-collapse v-model:activeKey="state.priceActiveKeyList" :bordered="false" style="margin-top:3.2rem">
        <template #expandIcon="{ isActive }">
          <caret-right-outlined :rotate="isActive ? 90 : 0" style="font-size:16px;margin-top:6px" />
        </template>
        <a-collapse-panel :key="priceObj.key" :style="customStyle" v-for="priceObj in config">
          <template #header>
            <div class="useDetail-table-header" style="color:black;">
              <div class="price-use-detail-version-detail-table-header">
                <span>{{priceObj.header.title}}</span>
              </div>
            </div>
          </template>
          <div style="width:100%;height:8px;background:#fff"></div>
          <div class="useDetail-table-content1">
            <a-collapse class="price-use-detail-level" v-model:activeKey="state.levelActiveKeyMap[priceObj.key]"
              :bordered="false">
              <a-collapse-panel class="price-use-detail-level-item" :style="customStyle" :key="levelObj.header.key"
                v-for="(levelObj) in priceObj.level" :showArrow="false">
                <template #header>
                  <div class="price-use-detail-level-header">
                    {{ levelObj.header.title }}
                  </div>
                </template>
                <template #extra>
                  <caret-right-outlined :rotate="state.levelActiveKeyMap[priceObj.key].includes(levelObj.header.key) ? 90 : 180" style="font-size:16px;margin-top:6px" />
                </template>
                <div class="price-use-detail-version-detail-container" v-for="(detailObj, index) in levelObj.detail"
                  :key="index" :style="detailObj.valueList.length === 0 ? secondHeadTitle : ''">
                  <!-- <div class="cleanDiv"></div> -->
                  <div class="price-use-detail-version-detail-table-body-header">
                    <span>{{detailObj.title}}</span>
                  </div>
                  <div class="price-use-detail-version-detail-table-body-content">
                    <div v-for="(detailValueObj, _ind) in detailObj.valueList" :key="_ind">
                      <img v-if="detailValueObj.iconable" :src="imgMap[detailValueObj.value]">
                      <span v-else>{{detailValueObj.value}}</span>
                    </div>
                  </div>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <div class="price-useDetail-tip">
      <div class="useDetail-tip-text1">额外说明：</div>
      <div class="useDetail-tip-text2" style="margin-top:10px">1.试用版仅限用户了解产品，不能用于商业活动。</div>
      <div class="useDetail-tip-text2">2.如果现有功能无法满足您的需求，我们提供定制开发服务。</div>
    </div>
  </div>
  <UseDetailMobile class="price-useDetail-container-mobile"></UseDetailMobile>
</template>

<script>
  import {
    CaretRightOutlined
  } from '@ant-design/icons-vue';
  import UseDetailMobile from './useDetailMobile.vue'
  import {
    defineComponent,
    reactive,
    ref
  } from 'vue'
  import {
    PRICE_DETAIL_CONFIG
  } from '@/utils/PAGE-CONFIG.js'

  export default defineComponent({
    name: 'price',
    components: {
      CaretRightOutlined,
      UseDetailMobile
    },
    setup() {
      const state = reactive({
        priceActiveKeyList: [],
        levelActiveKeyMap: {}
      })
      // let activeKey = ref(['0', '1', '2', '3', '4', '5', '6'])
      let levelActiveList = ref({})
      const customStyle = 'background: white;border-radius: 4px;border: 0';
      const customStyle1 = 'background: rgba(0, 170, 159, 0.05);border-radius: 4px;border: 0';
      const imgMap = {
        0: require('@image/price/useDetail/check_false.png'),
        1: require('@image/price/useDetail/check.png'),
        2: require('@image/price/useDetail/check_true.png')
      }

      const config = JSON.parse(JSON.stringify(PRICE_DETAIL_CONFIG))
      const secondHeadTitle = 'background: rgba(0, 170, 159, 0.05)'
      for (let i = 0; i < config.length; i++) {
        let priceObj = config[i]
        state.priceActiveKeyList.push(priceObj.key)
        let _levelList = priceObj.level
        state.levelActiveKeyMap[priceObj.key] = []
        for (let j = 0; j < _levelList.length; j++) {
          let _levelObj = _levelList[j]
          let _header = _levelObj.header
          state.levelActiveKeyMap[priceObj.key].push(_header.key)
        }
      }
      return {
        state,
        customStyle,
        config,
        imgMap,
        customStyle1,
        secondHeadTitle
      }
    }
  })

</script>
<style lang="less" scoped>
  .price-useDetail-container {
    margin-top: 50px;

    .price-useDetail-table {
      width: 1300px;
      // height: 974px;
      margin: auto;

      .price-useDetail-table-top {
        // display: flex;
        // justify-content: space-between;
        float: right;

        span {
          margin-left: 62px;
          font-family: 'SourceHanSansCN-Regular';
          font-size: 16px;
          font-weight: normal;
          line-height: 18px;
          color: #3D3D3D;
        }
      }

      .useDetail-table-header {
        font-family: 'SourceHanSansCN-Regular';
        font-size: 18px;
        font-weight: 500;
        color: #252525;
        display: flex;
        flex: 1;
      }

      .useDetail-table-content1 {
        // width: 1300px;
        // height: 178px;
        background-color: white;
        margin-left: 14%;
      }

      .useDetail-table-content2 {
        width: 1300px;
        height: 234px;
        background-color: white;

        .useDetail-table-row {
          height: 56px;
          display: flex;
          justify-content: flex-start;

          div {
            font-family: 'SourceHanSansCN-Regular';
            font-size: 16px;
            font-weight: normal;
            line-height: 56px;
            text-align: left;
            color: #666666;
          }
        }
      }
    }

    .price-useDetail-tip {
      width: 1284px;
      // height: 162px;
      margin: auto;
      margin-top: 80px;
      padding: 16px 24px;
      background: #F8FFFE;
      box-sizing: border-box;
      border: 1px solid #D5E4E2;

      .useDetail-tip-text1 {
        font-family: 'SourceHanSansCN-Regular';
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        color: #000000;
      }

      .useDetail-tip-text2 {
        font-family: 'SourceHanSansCN-Regular';
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        color: #666666;
      }
    }
  }

  .price-use-detail-header-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    // padding: 0 16px;
    font-family: SourceHanSansCN-Regular-Regular;
    font-size: 32px;
    font-weight: normal;
    line-height: 84px;
    letter-spacing: 0em;
    color: #252525;
  }

  .price-use-detail-header-title {
    width: 230px;
  }

  .price-use-detail-header-table {
    display: flex;
    justify-content: flex-end;
    width: 61%;
  }

  .price-use-detail-header-table>div {
    width: 28%;
    text-align: center;
  }

  .price-use-detail-version-detail-container {
    height: 56px;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }

  .price-use-detail-version-detail-table-header {
    width: 200px;
  }

  .price-use-detail-version-detail-table-header-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .price-use-detail-version-detail-table-header-content>div {
    width: 25%;
    text-align: center;
  }

  .price-use-detail-level-header{
    font-weight: 900;
    font-size: 18px;
    color: black;
  }

  .price-use-detail-version-detail-table-body-header {
    width: 25%;
    display: flex;
    align-items: center;
    padding-left: 24px;
  }

  .price-use-detail-version-detail-table-body-header>span {
    font-size: 16px;
    color: #666666;
    letter-spacing: 0px;
  }

  .price-use-detail-version-detail-table-body-content {
    // flex: 1;
    width: 61%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .price-use-detail-version-detail-table-body-content>div {
    width: 33.33%;
    text-align: center;
    font-family: SourceHanSansCN-Regular;
  }

  @media screen and (max-width: 1300px) {
    .price-useDetail-container {
      overflow-x: scroll;
    }
  }

  @media screen and (max-width: 800px) {
    .price-useDetail-container {
      display: none;

      .price-useDetail-table {
        .price-useDetail-table-top {
          float: none;
          margin: auto;

          span {
            margin-left: 30px;
            font-family: 'SourceHanSansCN-Regular';
            font-size: 16px;
            font-weight: normal;
            line-height: 18px;
            color: #3D3D3D;
          }
        }

        .price-useDetail-table-title {
          margin-left: 20px;
        }
      }
    }
  }

  @media screen and (min-width: 800px) {
    .price-useDetail-container-mobile {
      display: none;
    }
  }

  .cleanDiv {
    width: 14%;
    height: 100%;
    background: #fff;
  }

</style>
<style>
  .price-useDetail-container .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
  }

  .price-useDetail-table .ant-collapse-header {
    background: rgba(0, 170, 159, 0.05);
  }

  .price-useDetail-container .price-use-detail-version-detail-container {
    padding: 0;
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
    padding-bottom: 12px;
  }

</style>
