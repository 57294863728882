<template>
  <div class="resource-container">
    <resourceBanner @placeholder="placeholder" @inputChange="inputChange"></resourceBanner>
    <resourceVideo :searchName="searchName" ref="searchVideo"></resourceVideo>
    <FooterBanner></FooterBanner>
  </div>
</template>
  
<script>
import {
  defineComponent, ref
} from 'vue'
import resourceBanner from './resourceBanner.vue'
import resourceVideo from './resourceVideo.vue'
import FooterBanner from '@/views/home/footerBanner.vue'

export default defineComponent({
  name: 'resource',
  components: {
    resourceBanner,
    resourceVideo,
    FooterBanner
  },
  setup() {
    const searchVideo = ref()
    const searchName = ref('')
    const placeholder = val => {
      searchVideo.value.searchVideo(val)
    }
    const inputChange = val => {
      searchName.value = val
    }
    return {
      searchVideo,
      searchName,
      placeholder,
      inputChange
    }
  }
})

</script>
<style scoped>
.resource-container {
  min-height: 100%;
}
</style>
  
