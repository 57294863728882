import {
    get,
    post
  } from "@/axios/http.js";
  import qs from 'qs'
  
  const EXAMPLE_APP_CONFIG = {
    "App-Key": "61af041a1f3214a20ff35264",
    "Sign": "PrUM8Hf5KYc4QnLEDtPH1edGNwF8Mew3P.tJc5I3LEmws2SQF.vSjmuPfONB0N1Fh-jZglqYdC0YvnAkz0GWf2yNKRA8huJkpR5tazQXN88u.tZ5t1TIimBQS0Fkjm3u",
  }
  export const getClassify = () => {
    let params = {
      "App-Key": EXAMPLE_APP_CONFIG['App-Key'],
      "Sign": EXAMPLE_APP_CONFIG['Sign'],
      "worksheetId": "64e4a01c6bd3e9ac8e08ba94",
      "pageSize": 100000,
      "pageNo": 1,
      "sortField": "",
      "isAsc": false,
      "keywords": "",
      "filters": []
    }
    return post('./api/v2/worksheet/getFilterRows', params)
  }
  export const getLevel = () => {
    let params = {
      "App-Key": EXAMPLE_APP_CONFIG['App-Key'],
      "Sign": EXAMPLE_APP_CONFIG['Sign'],
      "worksheetId": "64e4a1726bd3e9ac8e08bf84",
      "pageSize": 100000,
      "pageNo": 1,
      "sortField": "",
      "isAsc": false,
      "keywords": "",
      "filters": []
    }
    return post('./api/v2/worksheet/getFilterRows', params)
  }
  export const getTag = () => {
    let params = {
      "App-Key": EXAMPLE_APP_CONFIG['App-Key'],
      "Sign": EXAMPLE_APP_CONFIG['Sign'],
      "worksheetId": "64e4a03f6bd3e9ac8e08bb0c",
      "pageSize": 100000,
      "pageNo": 1,
      "sortField": "",
      "isAsc": false,
      "keywords": "",
      "filters": []
    }
    return post('./api/v2/worksheet/getFilterRows', params)
  }
  export const getVideo = () => {
    let params = {
      "App-Key": EXAMPLE_APP_CONFIG['App-Key'],
      "Sign": EXAMPLE_APP_CONFIG['Sign'],
      "worksheetId": "64e48b486bd3e9ac8e0872f6",
      "pageSize": 100000,
      "pageNo": 1,
      "sortField": "",
      "isAsc": false,
      "keywords": "",
      "filters": []
    }
    return post('./api/v2/worksheet/getFilterRows', params)
  }

  