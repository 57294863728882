<template>
    <div>
        <Banner></Banner>
        <ApplicationConstruction></ApplicationConstruction>
        <Cost></Cost>
        <Scene></Scene>
        <FooterBanner></FooterBanner>
    </div>
</template>
  
  <script>
    import {
      defineComponent,
    } from 'vue'
    import ApplicationConstruction from './applicationConstruction.vue'
    import Banner from './banner.vue'
    import Scene from './scene.vue'
    import Cost from './cost.vue'
    import FooterBanner from '../home/footerBanner.vue'
    export default defineComponent({
      name: 'App',
      components: {
        Banner,
        Scene,
        Cost,
        FooterBanner,
        ApplicationConstruction
      },
      setup() {
        return {
        }
      }
    })
  
  </script>
  <style scoped>
    .home-backimg{
      /* background-image: url("../../assets/image/body_bg.png"); */
    }
  </style>
  
