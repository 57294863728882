<template>
  <a-card class="cgs-card-item" hoverable :class="popVisible?'active-menu':''" @click="clickCard(scene)">
    <div class="cgs-card-item-content">
      <div class="cgs-card-item-img-container" ref="cgsCardImg" :style="{height: cardImgHeight + 'px'}">
        <img v-if="loading" class="cgs-card-item-img" :src="scene.img && scene.img !== '' ? scene.img : defaultImg" />
        <div class="cgs-card-item-icon-container">
          <a-tooltip placement="top" v-if="scene.linkScenePath && scene.linkScenePath !== ''">
            <template #title>
              <span>有demo链接</span>
            </template>
            <span class="iconfont icon-lianjie cgs-card-item-icon"></span>
          </a-tooltip>
          <a-tooltip placement="top" v-if="scene.videoPath && scene.videoPath !== ''">
            <template #title>
              <span>有视频链接</span>
            </template>
            <span class="iconfont icon-shipin cgs-card-item-icon"></span>
          </a-tooltip>
        </div>
        <div class="cgs-card-marking">  
            <img v-if="scene.linkScenePath && scene.linkScenePath !== ''" :src="require('@image/ico-play1.png')" alt="" @click="enterDEMO(scene)">
            <img v-if="scene.videoPath && scene.videoPath !== ''" :src="require('@image/24gf-playCircle.png')" alt="" @click.stop="enterVideo(scene)">
        </div>
       
      </div>

      <div v-if="loading" class="cgs-card-footer">
        <!-- <slot name="bottom" :data="scene"></slot> -->
        <div class="cgs-card-footer-bottom-text">
          <div class="cgs-card-footer-title-container"><span>{{ scene.title }}</span></div>
          <div class="cgs-card-footer-desc-container"><span>{{ scene.briefIntroduction }}</span></div>
        </div>
        <div class="cgs-card-footer-bottom-button" v-if="scene.linkScenePath && scene.linkScenePath !== ''">
          <a-button shape="circle" size="large" @click="enterDEMO(scene)">
            <template #icon>
              <arrow-right-outlined />
            </template>
          </a-button>

        </div>
      </div>
    </div>

    <div class="cgs-card-tool" v-if="menu.length > 0">
      <a-popover v-model:visible="popVisible" placement="leftTop" class="cgs-card-tool-popover">
        <template #content>
          <div class="cgs-card-tool-menu-container">
            <div v-for="item in menu" :key="item.id" class="cgs-card-tool-menu-item" @click="clickMenu(scene, item)">
              <span class="cgshnIcon cgs-card-list-item-icon" :class="item.icon"></span>
              <span>{{ item.title }}</span>
            </div>
          </div>
        </template>
        <span class="cgshnIcon cgshnIcon-gengduo"></span>
      </a-popover>
    </div>
  </a-card>

</template>

<script>
  import {
    ref,
    defineComponent,
    watch,
    reactive,
    onMounted,
    getCurrentInstance,
    computed
  } from 'vue'
  import {
    ArrowRightOutlined
  } from "@ant-design/icons-vue"

  export default defineComponent({
    components: {
      ArrowRightOutlined
    },
    props: {
      scene: {
        type: Object,
        default: () => {
          return {}
        }
      },
      menu: {
        type: Array,
        default: []
      },
      defaultImg: {
        type: String,
        default: ''
      }
    },
    setup(props, ctx) {
      let loading = ref(false)
      let {
        proxy
      } = getCurrentInstance();
      const popVisible = ref(false)
      const cgsCardImg = ref(null)
      const cardImgHeight = ref(240)
      const clickCard = (obj) => {
        ctx.emit('clickCard', obj)
      }
      const clickMenu = (scene, menuItem) => {
        popVisible.value = false
        ctx.emit('clickMenu', scene, menuItem)
      }
      let resizer = () => {
        if (cgsCardImg.value === null || !'clientWidth' in cgsCardImg.value) {
          return
        }
        cardImgHeight.value = cgsCardImg.value.clientWidth / 2
        loading.value = true
      }
      onMounted(() => {
        setTimeout(() => {
          resizer()
        }, 100)
        window.addEventListener('resize', () => {
          resizer()
        })
      })
      const enterVideo = (data) => {
        ctx.emit('enterVideo', data)
      }
      const enterDEMO = (data) => {
        ctx.emit('enterDEMO', data)
      }
      return {
        cgsCardImg,
        cardImgHeight,
        popVisible,
        clickCard,
        clickMenu,
        enterVideo,
        enterDEMO,
        loading
      }
    }
  })

</script>

<style scoped>
  .cgs-card-list-item-icon {
    margin-right: 10px
  }

  .cgs-card-tool-menu-container {
    border: 1px solid #d9d9d9;
  }

  .cgs-card-tool-menu-item {
    padding: 5px 10px;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
  }

  .cgs-card-tool-menu-item:hover {
    background: #1890ff;
    color: rgb(255, 255, 255);
  }

  .cgs-card-item {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 12px 0px rgba(95, 78, 192, 0.1);
    margin-bottom: 2rem;
  }

  .cgs-card-item-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .cgs-card-item-img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    /* text-align: center; */
  }

  .cgs-card-item-img-container {
    text-align: center;
    width: 100%;
    height: calc(100% - 60px);
    position: relative;
    /* margin-bottom: 5px; */
  }

  .active-menu .cgs-card-item-img {
    /* filter: blur(3px) */
  }


  .cgs-card-item:not(.active-menu):hover .cgs-card-item-img {
    /* filter: blur(3px) */
  }

  /* .cgs-card-footer {
    text-align: center;
  } */

  .cgs-card-tool {
    /* display: none; */
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0;
    color: #9e9e9e;
    transition: all .25s ease-out;
    background: #fff;
    border-radius: 3px;
  }

  .active-menu .cgs-card-tool {
    opacity: 1;
  }

  .cgs-card-item:not(.active-menu):hover .cgs-card-tool {
    opacity: 1;
  }

  @media screen and (min-width: 1300px) {
    .cgs-card-item {
      width: calc(25% - 4rem);
    }

    .cgs-card-item:last-child:nth-child(4n - 1) {
      margin-right: calc(25% - 4rem + 16rem / 3);
    }

    .cgs-card-item:last-child:nth-child(4n - 2) {
      margin-right: calc(2 * (25% - 4rem + 16rem / 3));
    }

    .cgs-card-item-icon-container {
      display: block;
    }

    .cgs-card-marking {
      visibility: hidden;
      opacity: 0;
    }

    .cgs-card-item-img-container:hover .cgs-card-marking {
      visibility: visible;
      opacity: 1;
    }

    .cgs-card-footer-bottom-text:hover .cgs-card-footer-title-container {
    }

    .cgs-card-footer-bottom-text:hover .cgs-card-footer-desc-container {
    }

    .cgs-card-footer-bottom-button {
      display: none;
    }

    .cgs-card-footer-bottom-text {
      min-height: 100px;
    }
  }

  @media screen and (max-width: 1300px) and (min-width: 800px) {
    .cgs-card-item {
      width: calc(33.3% - 4rem);
    }

    .cgs-card-item:last-child:nth-child(3n - 1) {
      margin-right: calc(33.3% - 4rem + 12rem / 2);
    }

    .cgs-card-marking {
      visibility: hidden;
      opacity: 0;
    }

    .cgs-card-item-icon-container {
      display: block;
    }

    .cgs-card-item-img-container:hover .cgs-card-marking {
      visibility: visible;
      opacity: 1;
    }

    .cgs-card-footer-bottom-text:hover .cgs-card-footer-title-container {
    }

    .cgs-card-footer-bottom-text:hover .cgs-card-footer-desc-container {
    }

    .cgs-card-footer-bottom-button {
      display: none;
    }

    .cgs-card-footer-bottom-text {
      min-height: 100px;
    }
  }

  @media screen and (max-width: 800px) {
    .cgs-card-item {
      width: calc(50% - 1rem);
    }

    .cgs-card-marking {
      visibility: visible;
      opacity: 1;
    }

    .cgs-card-item-icon-container {
      display: none;
    }

    .cgs-card-footer-bottom-button {
      display: block;
    }

    .cgs-card-footer-bottom-text {
      min-height: unset;
    }
  }

  .cgs-card-footer {
    margin-bottom: 10px;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cgs-card-item-icon-container {
    position: absolute;
    top: 7px;
    right: 7px;
    color: #fff;
    box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 30%);
    border-radius: 3px;
    z-index: 2;

  }

  .cgs-card-item-icon {
    padding: 5px;
  }

  .cgs-card-footer-bottom-button {
    text-align: center;
  }

  .cgs-card-marking {
    /* display: none; */
    transition: opacity 0.5s;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    /* background: rgba(255, 255, 255, 0.5); */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .cgs-card-marking>img {
    height: 50%;
  }


  .cgs-card-footer-bottom-text {
    padding: 10px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
  }

  .cgs-card-footer-title-container {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .cgs-card-footer-desc-container {
    color: #333;
    font-size: 14px;
    letter-spacing: 1.5px;
    flex: 1;
    font-family: "Microsoft YaHei", SourceHanSansSC, PingFang SC !important;
  }
</style>



<style>
  .cgs-card-item .ant-card-body {
    padding: 0px;
    height: 100%;
  }

  .ant-popover-inner-content {
    padding: 0px !important;
  }

  .cgs-card-item.ant-card-hoverable:hover {
    border-color: #f0f0f0;
    box-shadow: unset;
    box-shadow: 0px 8px 12px 0px rgba(13, 126, 115, 0.1);
  }

  .cgs-card-footer-bottom-button .ant-btn:hover,
  .cgs-card-footer-bottom-button .ant-btn:focus {
    color: #08979C;
    border-color: #08979C;
    background: #fff;
  }

</style>
