<template>
    <!-- <div class="resource-videoCard" ref="cgsCardImg" :style="{ height: cardImgHeight + 'px' }"> -->
    <div class="resource-videoCard">
        <div class="resource-videoCard-top">
            <img :src=src class="resource-videoCard-top-img" @mouseenter="showPlay" @mouseleave="notShowPlay"
                @click="showVideo">
            <div class="resource-videoCard-top-font">
                <div class="resource-videoCard-top-font-title">{{ props.videoName }}</div>
                <div class="resource-videoCard-top-font-intro">{{ props.videoIntro }}</div>
            </div>
            <div v-if="show" class="resource-videoCard-top-play">
                <img src="@image/play.png" width="50" height="50">
                <span class="resource-videoCard-top-play-font">观看视频</span>
            </div>
        </div>
        <div class="resource-videoCard-bottom">
            {{ videoName }}
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({
    videoName: String,
    imageUrl: String,
    videoPath: String,
    videoIntro: String,
})

const src = props.imageUrl
const show = ref(false)
const showPlay = () => {
    show.value = true
}
const notShowPlay = () => {
    show.value = false
}
const emit = defineEmits(['changeShow'])
const showVideo = () => {
    emit('changeShow', props.videoPath)
}
const cardImgHeight = ref()
let resizer = () => {
    cardImgHeight.value = document.documentElement.clientWidth
}
onMounted(() => {
    setTimeout(() => {
        resizer()
    }, 100)
    window.addEventListener('resize', () => {
        resizer()
    })
})
</script>

<style scoped>
.resource-videoCard {
    display: flex;
    flex-direction: column;
    width: 400px;
    /* height: 310px; */
    height: 100%;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: all 100ms linear;
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
}

.resource-videoCard:hover {
    transform: scale(1.05);
    transition: all 100ms linear;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.12);
}

.resource-videoCard-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 10px;
}

.resource-videoCard-top-img {
    width: 100%;
    /* height: 250px; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    filter: brightness(100%);
}

.resource-videoCard-top-img:hover {
    filter: brightness(50%);
}

.resource-videoCard-top-font {
    position: absolute;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    pointer-events: none;
}

.resource-videoCard-top-font-title {
    font-family: Alibaba PuHuiTi;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(12, 80, 85, 1);
}

.resource-videoCard-top-font-intro {
    font-family: Alibaba PuHuiTi;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 10px;
    color: rgba(12, 80, 85, 1);
}

.resource-videoCard-top-play {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -4rem;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.resource-videoCard-top-play-font {
    color: #FFFFFF;
    margin-top: 1rem;
}

.resource-videoCard-bottom {
    width: 100%;
    height: 60px;
    background-color: #FFFFFF;
    color: #000;
    font-size: 17px;
    line-height: 60px;
    padding-left: 2rem;
    border-radius: 10px;
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
    .resource-videoCard {
        height: calc(100% - 60px);
        width: 40rem;
    }

    .resource-videoCard-top-font {
        width: 40rem;
    }

    .resource-videoCard-top-font-title {
        font-size: 3rem;
    }

    .resource-videoCard-top-font-intro {
        font-size: 1rem;
        margin-top: 0.5rem;
    }
}

@media screen and (max-width: 800px) {
    .resource-videoCard {
        width: 48%;
        margin-left: 0;
        margin-right: 0;
    }

    .resource-videoCard-top-font {
        width: 60rem;
    }

    .resource-videoCard-top-font-title {
        font-size: 4rem;
    }

    .resource-videoCard-top-font-intro {
        font-size: 2rem;
        margin-top: 1rem;
        display: none;
    }
}</style>