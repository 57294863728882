<template>
    <div class="fixedBox" v-if="visiable">
        <div class="fixedBox-top">
            <div class="fixedBox-top-item fixedBox-top-item-phone">
                <a-popover placement="leftTop" overlayClassName="fixedBox-top-item-popover">
                    <template #content>
                        <div class="phone-icon-content">
                            电话：010-62326117
                        </div>
                    </template>
                    <img :src="require('@image/phone-right.png')" class="phone-icon">
                </a-popover>
            </div>
            <div class="fixedBox-top-item">
                <a-popover placement="leftTop" overlayClassName="fixedBox-top-item-popover">
                    <template #content>
                        <div class="pgcode-icon-content">
                            <div class="pgcode-item pgcode-item-wxgongzhonghao">
                                <img :src="require('@image/QRCode/wxgongzhonghao.png')" alt="微信公众号" class="pgcode-img"/>
                                <div>关注畅图云公众号</div>
                            </div>
                            <div class="pgcode-item">
                                <img :src="require('@image/QRCode/wxxiaochengxu.png')" alt="微信小程序" class="pgcode-img"/>
                                <div>畅图云小程序</div>
                            </div>
                        </div>
                    </template>
                    <img :src="require('@image/pgcode-right.png')" class="pgcode-icon">
                </a-popover>
            </div>
        </div>
        <div class="fixedBox-bottom" @click="handleScrollTop">
            <div class="top-icon">
                <img :src="require('@image/top-right.png')">
            </div>
        </div>
    </div>
</template>

<script>
  import {
    defineComponent,
    ref,
    onMounted,
    onUnmounted,
  } from 'vue'
  export default defineComponent({
    name: 'fixedBox',
    setup() {
        const scrollTop = ref('')
        const visiable = ref(false) 
        let done = false
        function handleScroll() {
            scrollTop.value =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop
            scrollTop.value > 500 ? (visiable.value = true) : (visiable.value = false)
        }

        function step() {
            scrollTop.value <= 0 ? (done = true) : (done = false)
            window.scrollTo({
                top: (scrollTop.value -= 100)
            })
            if (!done) {
                window.requestAnimationFrame(step)
            }
        }
        function handleScrollTop() {
            window.requestAnimationFrame(step)
        }
        onMounted(() => {
            window.addEventListener('scroll', handleScroll,true)
        })
        onUnmounted(() => {
            window.removeEventListener('scroll', handleScroll,true)
        })

        return {
            scrollTop,
            visiable,
            handleScroll,
            handleScrollTop,
        }
    }
  })

</script>

<style scoped>
@media screen and (max-width: 800px) {
    .fixedBox{
        display: none;
    }
}
    
    .fixedBox{
        z-index:10;
        position:fixed;
        right:50px;
        top:40%;
    }
    .fixedBox-top{
        background: rgba(152,157,167,0.1);
    }
    .fixedBox-bottom{
        margin-top: 10px;
        padding: 14px;
        background: rgba(152,157,167,0.1);
    }
    .fixedBox-top-item, .fixedBox-bottom{
        cursor: pointer;
    }
    .fixedBox-top-item{
        padding: 14px;
    }
    .phone-icon-content{
        padding: 2rem 3rem;
    }
    .fixedBox-top-item-phone{
        border-bottom: 1px solid rgba(152,157,167,0.2);
    }
    .pgcode-icon-content{
    }
    .pgcode-item{
        text-align: center;
        padding: 2rem 3rem;
    }
    .pgcode-item-wxgongzhonghao{
        border-bottom: 1px solid rgba(152,157,167,0.2);
    }
    .pgcode-img{
        width: 11rem;
    }
</style>

<style>
    .fixedBox-top-item-popover.ant-popover.ant-popover-placement-leftTop{
        z-index:10;
        position:fixed;    
    }
</style>