<template>
  <div class="price-priceShow-container">
    <div class="price-priceShow-text">
      <div class="price-priceShow-text1">无需编程，人人都能搭建应用</div>
      <div class="price-priceShow-text2">支持私有化部署</div>
    </div>
    <div class="price-priceShow-cards price-priceShow-cards-pc">
      <div v-for="item in priceList" :key="item.id" class="price-priceShow-card"
        :style="{'background-image': `url(${require('@image/price/priceShow/card-bg.png')})`, 'background-size': 'cover'}">
        <div class="price-priceShow-card-title">
          {{item.title}}
        </div>

        <div class="price-priceShow-card-money">
          <div style="font-size: 32px;color: #333333;padding-top:3px">
            <!-- <span v-if="item.id != 1">¥</span> -->
          </div>
          <div v-if="item.id == 1">{{item.price}}</div>
          <div v-else style="width:100%;height:5.6rem;display:flex;align-items:center;justify-content: center;">
            <div style="width:50px;height:3px;background:#00AA9F;"></div>
          </div>
        </div>
        <div class="price-priceShow-card-bts">
          <a-button class="card-bt card-btstyle" href="https://app.mapaas.com/register" v-if="item.id === '1'" type="primary" target="_blank">审核注册&emsp;></a-button>
          <a-button class="card-bt" v-if="item.id === '3'" type="primary" @click="openContactModal">
            购买咨询&emsp;></a-button>
            <a-button class="card-bt" v-if="item.id === '4'" type="primary" @click="openContactModal">
            联系顾问&emsp;></a-button>
          <!-- <a-button class="card-bt2" v-if="item.id === '4'">在线咨询</a-button> -->
        </div>
        <div class="price-priceShow-card-detail">
          <div class="detail-item" v-for="detail in item.detailList" :key="detail">
            <img style="margin-bottom:8px" src="@image/price/priceShow/check-icon.png">
            <span>{{detail}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="price-priceShow-cards-mobile">
      <a-carousel autoplay>
        <div v-for="item in priceList" :key="item.id" class="price-priceShow-card"
          :style="{'background-image': `url(${require('@image/price/priceShow/card-bg.png')})`, 'background-size': 'cover'}">
          <div class="price-priceShow-card-title">
            {{item.title}}
          </div>
          <div class="price-priceShow-card-money">
            <div style="font-size: 32px;color: #333333;padding-top:3px" v-if="item.price&&item.price!='免费'">¥</div>
            <div>{{item.price}}</div>
            <div v-if="!item.price" style="width:100%;height:5.6rem;display:flex;align-items:center;justify-content: center;">
              <div style="width:50px;min-height:3px;background:#00AA9F;"></div>
            </div>
          </div>
          <div class="price-priceShow-card-bt">
            <a-button class="card-bt card-btstyle" href="https://app.mapaas.com/register" v-if="item.id === '1'" type="primary" target="_blank">审核注册&emsp;></a-button>
            <a-button class="card-bt" v-if="item.id === '3'" type="primary" @click="openContactModal">
              购买咨询&emsp;></a-button>
            <a-button class="card-bt" v-if="item.id === '4'" type="primary" @click="openContactModal">
              联系顾问&emsp;></a-button>
            <!-- <a-button class="card-bt2" v-if="item.id === '4'">联系购买</a-button> -->
          </div>
          <div class="price-priceShow-card-detail">
            <div class="detail-item" v-for="detail in item.detailList" :key="detail">
              <img style="margin-top:4px" src="@image/price/priceShow/check-icon.png">
              <span>{{detail}}</span>
            </div>
          </div>

        </div>
      </a-carousel>
    </div>
    <!-- <contact-buy :visible="contactModalVisible" title="联系购买" @close="closeContactModal"></contact-buy> -->
    <!-- <PriceModal :modalFlag="modalFlag" @priceModalClose="priceModalClose"></PriceModal> -->
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
    ref
  } from 'vue'
  import contactBuy from './contactBuy.vue'
  import {
    linkToCode
  } from '@/utils/tool'
  import PriceModal from '../consultation/index.vue'
  import { useRouter } from 'vue-router'
  export default defineComponent({
    name: 'price',
    components: {
      contactBuy,
      PriceModal
    },
    setup() {
      let contactModalVisible = ref(false)
      let priceList = reactive([{
          id: '1',
          title: '试用版',
          price: '免费',
          detailList: [
            '公有云',
            '1人内可用',
            '基础功能',
            '每类应用1个',
            '在线试用',
            '注册审核',
            '7天有效期'
          ]
        },
        {
          id: '3',
          title: '标准版',
          price: '',
          detailList: [
            '私有部署',
            '100人内可用',
            '高级功能',
            '每类应用5+个',
            '定制标识',
            '购买咨询',
            '永久使用',
          ]
        },
        {
          id: '4',
          title: '专业版',
          price: '',
          detailList: [
            '私有部署',
            '不限使用人数',
            '专业功能',
            '不限应用数量',
            '更优质的服务',
            '联系顾问',
            '永久使用',
          ]
        }
      ])
      /* {
          id: '2',
          title: '单应用版',
          price: '19800',
          detailList: [
            '含免费版所有功能',
            '20人内可用',
            '高级功能',
            '版权标识',
            '主题管理',
          ]
        }, */
      const modalFlag = ref(false)
      const router = useRouter()
      const openContactModal = () => {
        // modalFlag.value = true
        router.push('/consultation')
      }
      const closeContactModal = () => {
        contactModalVisible.value = false
      }
      const priceModalClose = (val) => {
        modalFlag.value = val
      }
      const toCode = linkToCode
      return {
        priceList,
        contactModalVisible,
        openContactModal,
        closeContactModal,
        toCode,
        modalFlag,
        priceModalClose
      }
    }
  })

</script>
<style lang="less" scoped>
  .price-priceShow-container {
    width: 100%;
    height: 926px;
    background: url('~@image/price/priceShow/bg.png');
    background-size: cover;
    background-repeat: no-repeat;

    .price-priceShow-text {
      padding-top: 100px;
      text-align: center;

      .price-priceShow-text1 {
        margin: auto;
        width: 70.2rem;
        height: 8.4rem;
        font-size: 5.4rem;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0em;
        color: #FFFFFF;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }

      .price-priceShow-text2 {
        margin: auto;
        width: 70.2rem;
        height: 8.4rem;
        font-size: 2.4rem;
        line-height: 8.4rem;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0em;
        color: #FFFFFF;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .price-priceShow-cards {
      width: 131rem;
      height: 582px;
      margin: auto;
      margin-top: 89px;
      display: flex;
      justify-content: center;
      cursor: pointer;

      .price-priceShow-card {
        border-radius: 12px;
        width: 30%;
        height: 57rem;
        padding: 4.8rem;
        box-shadow: 0px 4px 16px 0px rgba(0, 118, 126, 0.2);
        margin-left: 1.5rem;

        .price-priceShow-card-title {
          font-family: 'SourceHanSansCN-Bold-Bold';
          font-size: 3.2rem;
          font-weight: 700;
          line-height: 3.2rem;
          text-align: center;
          letter-spacing: 0px;
          color: #222222;
        }

        .price-priceShow-card-money {
          text-align: center;
          margin-top: 3.3rem;
          display: flex;
          justify-content: center;

          div {
            font-size: 4.8rem;
            font-weight: 700;
            line-height: 5.6rem;
            text-align: center;
            letter-spacing: 0px;
            color: #000000;
          }
        }

        .price-priceShow-card-detail {
          margin-top: 3rem;
          padding-left: 7rem;

          .detail-item span {
            margin-left: 1rem;
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 5rem;
            color: #333333;
          }
        }

        .price-priceShow-card-bt {
          text-align: center;
          margin-top: 3rem;

          .card-bt2,
          .card-bt {
            border-image: linear-gradient(180deg, #05CDCD 2%, #00A3B5 100%) 1;
            width: 24.6rem;
            height: 5.2rem;
            border-radius: 32px;
            opacity: 1;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 1px solid #05CDCD;
            box-shadow: 0px 4px 10px 0px rgba(0, 131, 131, 0.2);
            font-size: 2.4rem;
            transition-duration: 0.1s;
            color: #00AA9F;
          }

          .card-bt:hover {
            transition-duration: 0s;
            background: linear-gradient(90deg, #00AA9F 3%, #00DACC 97%);
            color: white;
          }

          .card-bt2 {
            background: linear-gradient(90deg, #00AA9F 3%, #00DACC 97%);
            color: #FFFFFF;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .price-priceShow-container {
      .price-priceShow-cards {
        .price-priceShow-card {
          padding: 3.6rem;
        }
      }
      .price-priceShow-card-money {
          text-align: center;
          margin-top: 3.3rem;
          display: flex;
          justify-content: center;

          div {
            font-size: 4.8rem;
            font-weight: 700;
            line-height: 5.6rem;
            text-align: center;
            letter-spacing: 0px;
            color: #000000;
          }
        }
    }
  }

  @media screen and (max-width: 800px) {
    .price-priceShow-container {
      .price-priceShow-cards-pc {
        display: none;
      }

      .price-priceShow-cards-mobile {
        // display: block;
        margin-top: 50px;

        .price-priceShow-card {
          border-radius: 12px;
          width: 320px;
          height: 750px;
          padding: 20px;
          box-shadow: 0px 4px 16px 0px rgba(0, 118, 126, 0.2);

          .price-priceShow-card-title {
            font-family: 'SourceHanSansCN-Bold-Bold';
            font-size: 32px;
            font-weight: 700;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0px;
            color: #222222;
          }

          .price-priceShow-card-money {
            text-align: center;
            margin-top: 33px;
            display: flex;
            justify-content: center;

            div {
              font-size: 48px;
              font-weight: 700;
              line-height: 36px;
              min-height: 36px;
              text-align: center;
              letter-spacing: 0px;
              color: #000000;
            }
          }

          .price-priceShow-card-detail {
            margin-top: 15px;
            padding-left: 30px;

						.detail-item{
							width: 214px;
							height: 30px;
							display: flex;
							justify-content: flex-start;
							span{
								margin-left: 10px;
								font-size: 18px;
								font-weight: 500;
								// line-height: 50px;
								color: #333333;
							}
							img{
								width: 21px;
								height: 21px;
							}
						}
					}
					.price-priceShow-card-bt{
						text-align: center;
						margin-top: 30px;

						.card-bt2,
						.card-bt{
							width: 246px;
							height: 52px;
							border-radius: 32px;
							opacity: 1;
							background: #FFFFFF;
							box-sizing: border-box;
							border: 1px solid #05CDCD;
							box-shadow: 0px 4px 10px 0px rgba(0, 131, 131, 0.2);
							font-size: 24px;
							transition-duration: 0.1s;
							color: #00AA9F;


						}
						.card-bt:hover{
							transition-duration: 0s;
							background: linear-gradient(90deg, #00AA9F 3%, #00DACC 97%);
							// border-image: linear-gradient(180deg, #05CDCD 2%, #00A3B5 100%) 1;
							color: white;
						}
						.card-bt2{
							background: linear-gradient(90deg, #00AA9F 3%, #00DACC 97%);
							color: #FFFFFF;
						}
					}
				}
			}
		}
	}
	@media screen and (min-width: 800px){
		.price-priceShow-container{
			.price-priceShow-cards-mobile{
				display: none;
			}
      .price-priceShow-card-detail {
          margin-top: 3rem;
          padding-left: 1rem;
          .detail-item{
            max-height: 3.5rem;
          }

          .detail-item span {
            margin-left: 1rem;
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 2.5rem;
            color: #333333;
          }
        }
		}
	}

  .detail-item{
    height: 40px!important;
  }
  .price-priceShow-card-detail{
    margin-top: 15px!important;
  }
  </style>
	<style>
	.price-priceShow-container .ant-carousel .slick-initialized .slick-slide > div{
		width: 320px;
		height: 550px;
		margin: auto;
		background-image: url('../../assets/image/price/priceShow/card-bg.png');
		background-size: cover;
	}
	.price-priceShow-container .ant-carousel .slick-initialized .slick-slide{
		height: 570px;
	}
		/* .price-priceShow-container .ant-btn{
			border-image: linear-gradient(180deg, #05CDCD 2%, #00A3B5 100%) 1;
			width: 246px;
			height: 52px;
			border-radius: 32px;
			opacity: 1;
			background: #FFFFFF;
			box-sizing: border-box;
			border: 1px solid #05CDCD;
			box-shadow: 0px 4px 10px 0px rgba(0, 131, 131, 0.2);
		} */

</style>
<style lang="less">
  .price-priceShow-container .ant-tabs-ink-bar {
    background: #08979C;
  }

  .price-priceShow-container .ant-tabs-tab:hover .home-features-text {
    color: #08979C;
  }

  .price-priceShow-container .ant-carousel .slick-dots-bottom {
    bottom: -30px;
  }

  .price-priceShow-container .ant-carousel .slick-dots {

    button {
      background-color: rgb(219, 215, 215);
      border-radius: 25px;
      width: 1.5rem;
      height: 1.5rem;
    }

    .slick-active button {
      width: 3.5rem;
      background-color: #08979C;
    }
  }

  .price-priceShow-card-bts{
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .card-bt{
      width: 18rem;
      height: 5rem;
      border-color: #1ad7cb;
      background: transparent;
      color: #1ad7cb;
      font-size: 2rem;
    }
    .card-bt:hover{
      width: 18rem;
      height: 5rem;
      border-color: #1ad7cb;
      background: #1ad7cb;
      color: #FFFFFF;
      font-size: 2rem;
    }
    .card-btstyle{
      line-height:5rem;
    }
  }

  .price-priceShow-card-bt{
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .card-bt{
      width: 18rem;
      height: 5rem;
      border-color: #1ad7cb;
      background: transparent;
      color: #1ad7cb;
      font-size: 2rem;
    }
    .card-bt:hover{
      width: 18rem;
      height: 5rem;
      border-color: #1ad7cb;
      background: #1ad7cb;
      color: #FFFFFF;
      font-size: 2rem;
    }
    .card-btstyle span{
      line-height:9rem;
    }
  }

</style>
