  <template>
  <div class="price-problem-container">
    <div class="price-problem-title">常见问题·解答</div>
    <a-collapse v-model:activeKey="activeKey" :bordered="false" style="margin-top:50px">
      <template #expandIcon="{ isActive }">
        <right-outlined :rotate="isActive ? 90 : 0" style="font-size:16px;margin-top:6px;margin-left:10px" />
      </template>
      <a-collapse-panel key="1" :style="customStyle">
        <template #header>
          <div class="price-problem-header">平台能支持哪些业务场景?</div>
        </template>
        <div class="price-problem-text">
          畅图云平台是专业的零代码可视化搭建平台，由数据采集、数据存储与管理、地学建模与分析、数据服务、可视化、系统集成等部分组成，广泛应用于矿山、地质、智慧工地、轨道交通、智慧园区等行业场景。
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="2" :style="customStyle">
        <template #header>
          <div class="price-problem-header">畅图云有哪些版本，是否对科研教育有针对性优惠政策？</div>
        </template>
        <div class="price-problem-text">
          畅图云目前分为试用版、标准版、专业版，已推出部分行业应用，同时支持OEM定制开发；我们对于科研和教育行业的用户制定了优惠政策，用户可以联系顾问咨询。
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
  import {
    RightOutlined
  } from '@ant-design/icons-vue';
  import {
    defineComponent,
    ref
  } from 'vue'

  export default defineComponent({
    name: 'price',
    components: {
      RightOutlined
    },
    setup() {
      let activeKey = ref(['1', '2'])
      const customStyle =
        'background: #FBFFFF;border-radius: 4px;overflow: hidden;border: 0;box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);margin-bottom:14px';

      return {
        activeKey,
        customStyle
      }
    }
  })

</script>
<style lang="less" scoped>
  .price-problem-container {
    max-width: 1300px;
    // min-height: 406px;
    margin: auto;

    .price-problem-title {
      width: 210px;
      height: 32px;
      margin: auto;
      margin-top: 80px;
      font-family: 'SourceHanSansCN-Regular-Regular';
      font-size: 32px;
      font-weight: normal;
      line-height: 32px;
      text-align: center;
      color: #252525;
    }

    .price-problem-header {
      margin-top: 6px;
      font-family: 'SourceHanSansCN-Regular';
      font-size: 16px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: 1px;
      color: #252525;
    }

    .price-problem-text {
      width: 100%;
      margin-left: 40px;
      padding-right: 50px;
      font-family: 'SourceHanSansCN-Regular';
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: 1px;
      color: #666666;
    }
  }

  @media screen and (max-width: 800px) {
    .price-problem-container {
      margin-left: 10px;
      padding-right: 20px;
      width: 100%;

      .price-problem-text {
        padding-right: 60px;
      }
    }
  }

</style>
