<template>
  <a-modal v-model:visible="vis" title="视频演示" @ok="handleOk" @cancel="handleCancel" destroy-on-close
    style="width: 80%; min-width: 400px; max-width: 800px;">
    <video class="video-model-video" controls autoplay>
      <source :src="path" />
      您的浏览器不支持 video 标签。
    </video>
    <div class="video-model-openScene" v-if="scenePath !== ''" @click="enterScene(scenePath)">查看场景</div>
    <template #footer>
      <a-button ghost class="video-model-close-button" @click="handleCancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
  import {
    ref,
    reactive,
    defineComponent,
    watch,
    getCurrentInstance
  } from 'vue'

  export default defineComponent({
    components: {},
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      path: {
        type: String,
        default: ''
      },
      scenePath: {
        type: String,
        default: ''
      }
    },
    setup(props, ctx) {
      let vis = ref(props.visible)
      let state = reactive({})
      watch(() => props.visible, (newValue) => {
        vis.value = newValue
        if (!newValue) {}
      })
      const handleOk = () => {
        close()
      }
      const handleCancel = () => {
        close()
      }
      const close = () => {
        ctx.emit('close')
      }
      const enterScene = (path) => {
        path && path !== '' && window.open(path)
      }
      return {
        vis,
        state,
        handleOk,
        handleCancel,
        enterScene,
      }
    }
  });

</script>

<style scoped>
  .video-model-video {
    width: 100%;
  }

  .video-model-openScene {
    color: #08979C;
    cursor: pointer;
    margin-top: 10px;
  }

</style>

<style>
  .video-model-close-button.ant-btn.ant-btn-background-ghost {
    margin-right: 10px;
    width: 80px;
    color: #08979C;
    border-radius: 5px;
    border-color: #08979C;
  }

  .video-model-close-button.ant-btn.ant-btn-background-ghost:hover {
    background-color: #08979C;
    border-color: #08979C;
    color: white;
    opacity: 0.9;
  }

</style>
