<template>
    <div class="cost-costBanner-container">
      <img src="@image/cost-serve.png" class="cost-costBanner-container-img">
      <img src="@image/cost-serve-phone.png" class="cost-costBanner-container-img-phone">
      <img src="@image/cost-serve-button.png" class="cost-costBanner-container-button" @click="openContactModal">
      <contact-buy :visible="contactModalVisible" title="购买咨询" @close="closeContactModal"></contact-buy>
    </div>
  </template>
    
  <script setup>
    import { ref, onMounted } from 'vue';
    import contactBuy from '../price/contactBuy.vue'
    let contactModalVisible = ref(false)
    const openContactModal = () => {
        contactModalVisible.value = true
    }
    const closeContactModal = () => {
        contactModalVisible.value = false
    }
  </script>
    
  <style scoped>
  .cost-costBanner-container {
    position: relative;
  }
  
  .cost-costBanner-container-img {
    width: 100%;
  }
  .cost-costBanner-container-img-phone{
    width: 100%;
  }


  @media screen and (min-width: 800px) {
    .cost-costBanner-container-img-phone{
        display: none;
    }
    .cost-costBanner-container-button{
      position: absolute;
      bottom: 20%;
      left: 29%;
      width: 9%;
      cursor:pointer; 
    }
  }
  
  @media screen and (max-width: 800px) {
    .cost-costBanner-container-img {
      display: none;
    }
    .cost-costBanner-container-button{
      position: absolute;
      bottom: 8%;
      left: 37.5%;
      width: 25%;
      cursor:pointer; 
    }
  }
  </style>
  