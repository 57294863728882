<template>
    <div class="price-container" id="priceTop">
        <PriceShow></PriceShow>
        <div class="price-bg" :style="{background: `url(${require('@image/body_bg2.png')})`, 'background-size': 'cover'}">
          <UseDetail></UseDetail>
          <Problem></Problem>
          <div style="height:80px"></div>
        </div>
        <FooterBanner></FooterBanner>
    </div>
</template>
  
<script>
  import PriceShow from './priceShow.vue'
  import FooterBanner from './footerBanner.vue'
  import UseDetail from './useDetail.vue'
  import Problem from './problem.vue'
  import {
    defineComponent
  } from 'vue'

  export default defineComponent({
    name: 'price',
    components: {
      PriceShow,
      FooterBanner,
      UseDetail,
      Problem
    },
    setup() {

      return {
      }
    }
  })

</script>
<style scoped>
.price-container{
  min-height: 100%;
}

</style>
  
