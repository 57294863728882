<template>
  <div class="home-features-container">
    <span class="home-features-title">特色功能</span>
    <div class="home-features-pc home-features-tabs">
      <a-tabs v-model:activeKey="activeKey" centered :tabBarGutter="tabBarGutter" @tabClick="tabClick">
        <a-tab-pane v-for="item in tabList" :key="item.id" ::key="item.id">
          <template #tab>
            <span class="home-features-text" :style="item.color?'color:#23C5D0':''">
              <img :src="item.icon" style="width:40px;height:40px;margin-bottom:5px">
              <br>
              {{item.title}}
            </span>
          </template>
          <div class="home-features-tabs-bottom">
            <div class="home-features-tabs-left">
              <div class="text-text1">
                <span :class="item.textSeat? 'text':'text1'">{{ item.text }}</span>
                <span :class="item.textSeat? 'text1':'text'">{{item.text1}}</span>
              </div>
              <div class="text2">{{item.text2}}</div>
              <div class="home-features-tabs-left-button">
                <a-button class="button-more" type="primary" @click="openContactModal">了解更多　></a-button>
              </div>
            </div>
            <div class="home-features-tabs-img">
              <img :src="item.imgUrl">
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
   
    <div class="home-features-mobile home-features-tabs">
      <a-carousel autoplay ref='carouselImg'>
        <div v-for="item in tabList" :key="item.id" class="features-mobile-images">
          <div class="text-text1">
              <span :class="item.textSeat? 'text':'text1'">{{ item.text }}</span>
              <span :class="item.textSeat? 'text1':'text'">{{item.text1}}</span>
            </div>
          <div class="features-mobile-image-container"><img class="features-mobile-image" :src="item.imgUrl" /></div>
          <div class="features-mobile-text">
            <div class="features-mobile-text2">{{item.text2}}</div>
            <a-button class="button-more" type="primary" @click="openContactModal">了解更多　></a-button>
          </div>
        </div>
      </a-carousel>
      <div class="carousel-buttom">
        <img :src="require('@image/left.png')" class="carousel-buttom-img" @click="handlePrevNext('left')">
        <img :src="require('@image/right.png')" class="carousel-buttom-img" @click="handlePrevNext('right')">
      </div>
    </div>
    <contact-buy :visible="contactModalVisible" title="了解更多" @close="closeContactModal"></contact-buy>
  </div>
</template>

<script>
  import {
    LeftCircleOutlined,
    RightCircleOutlined
  } from '@ant-design/icons-vue';
  import {
    defineComponent,
    ref,
    reactive,
    onMounted
  } from 'vue'
  import contactBuy from '../price/contactBuy.vue'
  export default defineComponent({
    components: {
      LeftCircleOutlined,
      RightCircleOutlined,
      contactBuy
    },
    setup() {
      let contactModalVisible = ref(false)
      let activeKey = ref('1')
      let tabList = reactive([{
          id: '1',
          title: '表单',
          icon: require('@image/features/new-tsgn_tab1.png'),
          textSeat: true,
          text: '零',
          text1: '代码创建表单',
          text2: '不仅有业务字段，还有空间和模型字段',
          imgUrl: require('@image/features/image1.png'),
          color: true
        },
        {
          id: '2',
          title: '算法工作流 ',
          icon: require('@image/features/new-tsgn_tab2.png'),
          textSeat: true,
          text: '自由',
          text1: '实现算法组合',
          text2: '建模算法库，支持表单触发',
          imgUrl: require('@image/features/image2.png'),
          color: false
        },
        {
          id: '3',
          title: '业务工作流',
          icon: require('@image/features/new-tsgn_tab3.png'),
          textSeat: true,
          text: '自动化',
          text1: '业务流程',
          text2: '支持审批、webhook、分支和表单触发',
          imgUrl: require('@image/features/image3.png'),
          color: false
        },
        {
          id: '4',
          title: '自定义BI',
          icon: require('@image/features/new-tsgn_tab4.png'),
          textSeat: false,
          text: '页面更',
          text1: '随心',
          text2: '超多组件，随意搭建自定义页面',
          imgUrl: require('@image/features/image4.png'),
          color: false
        },
        {
          id: '5',
          title: '可视化2D/3D/BIM',
          icon: require('@image/features/new-tsgn_tab5.png'),
          textSeat: true,
          text: '多元化',
          text1: '展示',
          text2: '2D/3D/BIM/Earth/大屏......',
          imgUrl: require('@image/features/image5.png'),
          color: false
        },
        {
          id: '6',
          title: '报表',
          icon: require('@image/features/new-tsgn_tab6.png'),
          textSeat: true,
          text: '快速',
          text1: '生成',
          text2: '多种样式，动态数据',
          imgUrl: require('@image/features/image6.png'),
          color: false
        },
      ])
      let tabBarGutter = ref(100)
      const tabClick = (id) => {
        tabList.forEach(e => {
          if (e.id === id) {
            e.color = true
          } else {
            e.color = false
          }
        })
      }
      const _initRem = () => {
        // 获取屏幕宽度
        var width = document.documentElement.clientWidth
        // 设置根元素字体大小。此时为宽的10等分
        if (width < 1300) {
          tabBarGutter.value = width / 20
        } else {
          tabBarGutter.value = 100
        }
      }
      const carouselImg = ref(null)
      const handlePrevNext = (value)=>{
        if(value === 'left'){
          carouselImg._value.prev();
        }else if(value === 'right'){
          carouselImg._value.next();
        }
      }
      const openContactModal = () => {
        contactModalVisible.value = true
      }
      const closeContactModal = () => {
        contactModalVisible.value = false
      }
      onMounted(() => {
        _initRem()
        // 监听手机旋转的事件的时机，重新设置
        window.addEventListener('orientationchange', _initRem)
        // 监听手机窗口变化，重新设置
        window.addEventListener('resize', _initRem)
      })
      return {
        contactModalVisible,
        activeKey,
        tabList,
        tabClick,
        tabBarGutter,
        handlePrevNext,
        carouselImg,
        openContactModal,
        closeContactModal
      }
    }
  })

</script>

<style lang="less" scoped>
  .home-features-container {
    // height: 75.8rem;
    text-align: center;
    margin-top: 123px;
  }

  .home-features-title {
    font-family: "SourceHanSansCN-Bold";
    font-size: 4.8rem;
    // font-weight: bold;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    color: #333333;
  }

  .home-features-tabs {
    max-width: 1249px;
    // height: 758px;
    margin: auto;
    margin-top: 30px;

    .home-features-text {
      // font-family: "SourceHanSansCN-Regular";
      font-size: 18px;
      font-weight: normal;
      // line-height: 18px;
      letter-spacing: 0px;
    }

    // .home-features-text:hover{
    //   color: #23C5D0;
    // }
    .home-features-tabs-bottom {
      display: flex;
      justify-content: flex-start;
      margin-top: 84px;
      padding-bottom: 15px;

      .home-features-tabs-left {
        width: 415px;
        padding-left: 12px;

        .text-text1 {
          text-align: left;
          margin-top: 13.9rem;
          // font-family: "SourceHanSansCN-Bold";
          // font-size: 40px;
          // font-weight: bold;
          // line-height: 32px;
          letter-spacing: 3px;
          // color: #23C5D0;
          // text-shadow: 0px 16px 16px rgba(34, 128, 196, 0.2);
        }
        .text{
          font-size: 52px;
          font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
          font-weight: 400;
          color: #23C5D0;
          line-height: 75px;
        }
        .text1{
          font-size: 36px;
          font-family: FZXiHei I-Z08S-Regular, FZXiHei I-Z08S;
          font-weight: 400;
          color: #333333;
          line-height: 56px;
        }
        .text2 {
          margin-top: 5px;
          text-align: left;
          font-weight: normal;
          letter-spacing: 0px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
          font-weight: 400;
          color: #6B7487;
          line-height: 19px;
        }

        .home-features-tabs-left-button {
          font-size: 16px;
          font-weight: 400;
          margin-top: 43px;
          text-align: left;
        }
        .button-more{
          width: 170px;
          height: 50px;
          background-color: #23C5D0;
          border: none;
        }
      }

      .home-features-tabs-img {
        width: 82rem;
        height: 47.4rem;
      }

      .home-features-tabs-img img {
        width: 82rem;
        height: 46.5rem;
        border-radius: 4px;
        opacity: 1;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
        // background-image: url("../../assets/image/features/image1.png");
      }
    }

    // mobile 样式
    // .ant-carousel :deep(.slick-slide)>div {
    //   width: 82rem;
    //   height: 46.5rem;
    //   margin: auto;
    // }

    .features-mobile-images {
      // display: inline-flex !important;
      // flex-direction: column;
      // height: 200px;
    }

    .features-mobile-image-container {
      padding: 0 10px;
      display: flex;
      justify-content: center;
    }

    .features-mobile-image {
      max-width: 100%;
      width: 100%;
    }

    // .features-mobile-images>img {
    //   width: 100%;
    // }


    // .ant-carousel :deep(.slick-slide) {
    //   text-align: center;
    //   width: 82rem;
    //   height: 58rem;
    //   line-height: 160px;
    //   overflow: hidden;

    //   // div {
    //   //   width: 82rem;
    //   //   height: 46.5rem;
    //   //   margin: auto;
    //   // }

    //   .features-mobile-images {
    //     width: 82rem;
    //     height: 46.5rem;
    //     margin: auto;

    //     .features-mobile-image {
    //       width: 82rem;
    //       height: 46.5rem;
    //       border-radius: 4px;
    //       opacity: 1;
    //       box-shadow: 0 10px 10px rgba(0, 0, 0, .5);
    //     }
    //   }

    //   .features-mobile-text {
    //     position: absolute;
    //     height: 30px;
    //     color: black;
    //     top: 35rem;
    //   }
    // }
  }

  @media screen and (max-width: 800px) {
    .home-features-container{
      padding: 10rem 0;
      background: #F8FAFA;
    }
    .home-features-pc {
      display: none;
    }

    .home-features-mobile {
      padding: 3rem;
      display: block;
    }

    .home-features-title {
      font-size: 8rem;
    }
    .features-mobile-images{
      background-color: #FFFFFF;
      padding: 3rem;
    }
    .features-mobile-text{
      text-align: left;
      padding: 0 1rem;
    }
    .features-mobile-text2{
      margin: 3rem 0;
    }
    .text-text1{
      padding: 0 1rem;
      margin-bottom: 3rem;
      text-align: left;
      font-weight: 400;
      .text{
        font-size: 4.8rem;
        color: #23C5D0;
      }
      .text1{
        font-size: 3.6rem;
        color: #333333;
      }
    }
    .button-more{
      color: #FFFFFF;
      border: none;
      background: #23C5D0;
      width: 26rem;
      height: 8rem;
    }
    .button-more:hover{
      background-color: #23C5D0;
      border-color: #23C5D0;
      color: white;
      opacity: 0.9;
    }
    .button-more:focus{
      background-color: #23C5D0;
      border-color: #23C5D0;
      color: white;
    }
    .carousel-buttom{
      display: flex;
      justify-content: space-between;
    }
    .carousel-buttom-img{
      margin: 0 20%;
      width: 6rem;
      height: 6rem;
    }
  }

  @media screen and (min-width: 800px) {
    .home-features-pc {
      display: block;
    }

    .home-features-mobile {
      display: none;
    }
  }

</style>

<style lang="less">
  .home-features-container .ant-tabs-ink-bar {
    background: #23C5D0;
  }

  .home-features-container .ant-tabs-tab:hover .home-features-text {
    color: #23C5D0;
  }

  .home-features-container .ant-carousel .slick-dots-bottom{
    bottom: -30px;
  }

  .home-features-container .ant-carousel .slick-dots {

    button {
      background-color: rgb(219, 215, 215);
      border-radius: 25px;
      width: 1.5rem;
      height: 1.5rem;
    }

    .slick-active button {
      width: 3.5rem;
      background-color: #23C5D0;
    }
  }

</style>
