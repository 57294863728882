<template>
    <div class="serve-serveBanner-container">
      <img src="@image/banner-serve.png" class="serve-serveBanner-container-img">
      <img src="@image/banner-serve-phone.png" class="serve-serveBanner-container-img-phone">
    </div>
  </template>
    
  <script setup>
  </script>
    
  <style scoped>
  .serve-serveBanner-container {
    position: relative;
    margin-top: 80px;
  }
  
  .serve-serveBanner-container-img {
    width: 100%;
  }
  .serve-serveBanner-container-img-phone{
    width: 100%;
  }
  
  
  /* @media screen and (min-width: 800px) and (max-width: 1300px) {
    .serve-serveBanner-container-img {
      width: 1300px;
    }
  } */
  @media screen and (min-width: 800px) {
    .serve-serveBanner-container-img-phone{
        display: none;
    }
  }
  
  @media screen and (max-width: 800px) {
    .serve-serveBanner-container-img {
      display: none;
    }
  }
  </style>
  